export const STATUS_TODO = 'TODO';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_READY = 'READY';
export const STATUS_DONE = 'DONE';
export const STATUS_ON_HOLD = 'ON_HOLD';
export const STATUS_CLOSED = 'CLOSED';
export const STATUS_CANCELLED = 'CANCELLED';

export const SUBTASK_STATUS_TODO = 'SUBTASK_TODO';
export const SUBTASK_STATUS_IN_PROGRESS = 'SUBTASK_IN_PROGRESS';
export const SUBTASK_STATUS_READY = 'SUBTASK_READY';
export const SUBTASK_STATUS_ON_HOLD = 'SUBTASK_ON_HOLD';

export let Statuses = {};

export function convertSimpleToComplex(oldStatus: Status) {
    return SubTaskTaskStatusMap[oldStatus.id];
}

export function convertComplexToSimple(oldStatus: Status) {
    return TaskSubTaskStatusMap[oldStatus.id];
}

export class Status {
    id: string;
    label: string;
    class: string;

    constructor(id: string, brief: string) {
        this.id = id;
        this.label = brief;
        this.class = id;
    }

}

Statuses[STATUS_CANCELLED] = new Status(STATUS_CANCELLED, 'Cancelled');
Statuses[STATUS_ON_HOLD] = new Status(STATUS_ON_HOLD, 'On hold');
Statuses[STATUS_TODO] = new Status(STATUS_TODO, 'Todo');
Statuses[STATUS_IN_PROGRESS] = new Status(STATUS_IN_PROGRESS, 'In progress');
Statuses[STATUS_READY] = new Status(STATUS_READY, 'Ready');
Statuses[STATUS_DONE] = new Status(STATUS_DONE, 'Done');
Statuses[STATUS_CLOSED] = new Status(STATUS_CLOSED, 'Closed');

Statuses[SUBTASK_STATUS_ON_HOLD] = new Status(SUBTASK_STATUS_ON_HOLD, 'On hold');
Statuses[SUBTASK_STATUS_TODO] = new Status(SUBTASK_STATUS_TODO, 'Todo');
Statuses[SUBTASK_STATUS_IN_PROGRESS] = new Status(SUBTASK_STATUS_IN_PROGRESS, 'In progress');
Statuses[SUBTASK_STATUS_READY] = new Status(SUBTASK_STATUS_READY, 'Ready');

export let taskStatusOrder = [
    Statuses[STATUS_CANCELLED],
    Statuses[STATUS_ON_HOLD],
    Statuses[STATUS_TODO],
    Statuses[STATUS_IN_PROGRESS],
    Statuses[STATUS_READY],
    Statuses[STATUS_DONE],
    Statuses[STATUS_CLOSED]
];

export let subTaskStatusOrder = [
    Statuses[SUBTASK_STATUS_ON_HOLD],
    Statuses[SUBTASK_STATUS_TODO],
    Statuses[SUBTASK_STATUS_IN_PROGRESS],
    Statuses[SUBTASK_STATUS_READY]
];

export let TaskSubTaskStatusMap = {};

TaskSubTaskStatusMap[STATUS_ON_HOLD] = Statuses[SUBTASK_STATUS_ON_HOLD];
TaskSubTaskStatusMap[STATUS_TODO] = Statuses[SUBTASK_STATUS_TODO];
TaskSubTaskStatusMap[STATUS_IN_PROGRESS] = Statuses[SUBTASK_STATUS_IN_PROGRESS];
TaskSubTaskStatusMap[STATUS_READY] = Statuses[SUBTASK_STATUS_READY];
TaskSubTaskStatusMap[STATUS_DONE] = Statuses[SUBTASK_STATUS_READY];
TaskSubTaskStatusMap[STATUS_CLOSED] = Statuses[SUBTASK_STATUS_READY];
TaskSubTaskStatusMap[STATUS_CANCELLED] = Statuses[SUBTASK_STATUS_READY];

export let SubTaskTaskStatusMap = {};

SubTaskTaskStatusMap[SUBTASK_STATUS_ON_HOLD] = Statuses[STATUS_ON_HOLD];
SubTaskTaskStatusMap[SUBTASK_STATUS_TODO] = Statuses[STATUS_TODO];
SubTaskTaskStatusMap[SUBTASK_STATUS_IN_PROGRESS] = Statuses[STATUS_IN_PROGRESS];
SubTaskTaskStatusMap[SUBTASK_STATUS_READY] = Statuses[STATUS_READY];
