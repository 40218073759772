<div id="wrapper" *ngIf="!!task">
    <button *ngIf="!!closeModal" class="btn" (click)="closeModal()" style="float:right">X</button>
    <div class="taskLabel">
        <span style="font-weight: bold">Label: </span>
        <span (keyup)="stopPropagation($event)" (keydown)="stopPropagation($event)">
            <input [(ngModel)]="task.brief" id="detailsBrief">
            <button class="btn" (click)="openTaskInNewTab(task)" *ngIf="task.subtasks.length > 0">Open</button>
        </span>
    </div>
    <br>

    <div class="status">
        <button *ngIf="task.status !== prevStatus()" id="setPrevStat" class="btn btn-default"
            (click)="setPrevStatus()">{{task.type.prevStatus(task.status)?.label}}</button>
        <span id="currentStatus"> {{task.status?.label}} </span>
        <button *ngIf="task.status !== nextStatus()" id="setNextStat" class="btn btn-default"
            (click)="setNextStatus()">{{task.type.nextStatus(task.status)?.label}}</button>
    </div>
    <br>

    <div class="priority">
        <span style="font-weight: bold">Priority: </span>
        <span class="taskPriority">
            <select [(ngModel)]="task.priority">
                <option *ngFor="let priority of Priorities" value="{{priority}}">{{priority}}</option>
            </select>
        </span>
    </div>
    <br>
    <div class="assignee">
        <span style="font-weight: bold" id="assigneeTitle">Assignee: </span>
        <span id="groupValue">{{ task.assignee?.name }}</span>
    </div>

    <br>
    <div class="deadline" (keydown)="stopPropagation($event)" (keyup)="stopPropagation($event)">
        <span style="font-weight: bold">Deadline: </span>
        <input id="taskDeadline" [(ngModel)]="task.deadline" [owlDateTimeTrigger]="dtTaskDeadline"
            [owlDateTime]="dtTaskDeadline" title="taskDeadline" />
        <owl-date-time [pickerType]="'calendar'" #dtTaskDeadline></owl-date-time>
    </div>
    <br>

    <tabs class="tabContainer" (currentTabChange)="determineSetter($event)">

        <tab tabTitle="General">
            <div id="description" (keyup)="stopPropagation($event)" (keydown)="stopPropagation($event)">
                <label id="descriptionTitle">Description:</label>
                <textarea id="descriptionTextArea" [value]="task.description" (blur)="setDescription($event)"
                    rows="4"></textarea>
            </div>
            <h4>History</h4>
            <xodos-history-list [listOfHistoryItems]="listOfHistoryItems"></xodos-history-list>
        </tab>

        <tab tabTitle="Worklogs">
            <xodos-worklog-list [task]="task"></xodos-worklog-list>
            <br>
            <br>
            <button (click)="openWorklog(task)" class="btn btn-default worklogButton"> WorkLog</button>
        </tab>

        <tab tabTitle="Status">
            <xodos-status-change-list [task]="task"></xodos-status-change-list>
        </tab>

        <tab tabTitle="Comments">
            <xodos-comment-list [task]="task"></xodos-comment-list>
            <br>
            <div (keyup)="stopPropagation($event)" (keydown)="stopPropagation($event)">
                <textarea [value]="comment" (blur)="changeComment($event)"></textarea>
                <br>
                <br>
                <button (click)="addComment(task)" class="btn btn-default">Add comment!</button>
            </div>
        </tab>

        <tab tabTitle="Templates">
            <xodos-apply-template [state]="state"></xodos-apply-template>
        </tab>

        <tab tabTitle="Export">
            <button (click)="export('html')">Export Html</button>
            <button (click)="export('txt')">Export Txt</button>
        </tab>

        <tab tabTitle="Misc.">
            <div>
                <xodos-estimation [task]="task" [config]="config" [clickHandler]="setEstimation"></xodos-estimation>
            </div>
            <br />
            <div id="context">
                <label>Context: </label>
                <select [(ngModel)]="task.context" (change)="setTaskContext($event)">
                    <option value=""></option>
                    <option class="chosenContexs" *ngFor="let context of Contexts" value="{{context}}">
                        {{ContextLabels[context]}}</option>
                </select>
            </div>
            <div class="type">
                <span style="font-weight: bold">Type: </span>
                <span id="tasktype">
                    <select [(ngModel)]="task.type">
                        <option *ngFor="let type of types" [ngValue]="type">{{type.code}}</option>
                    </select>
                </span>
            </div>
        </tab>
        <tab tabTitle="MassCreate" (keyup)="stopPropagation($event)" (keydown)="stopPropagation($event)">
            <xodos-mass-create [task]="task" [taskService]="taskService"></xodos-mass-create>
        </tab>
    </tabs>
</div>
