import { SystemUrlService, ConfigService as XOConfiService } from "@xo/client-common";
import { Injectable } from '@angular/core';

@Injectable()
export class ConfigUrlService extends SystemUrlService {

    constructor(system, configService: XOConfiService) {
        super(system, "Xodos", "Config", configService);
    }

    getConfigUrl(): string {
        return `${this.baseURL}/User`;
    }
}
