import { Template } from "./template.component";
import { Task } from "../../../task/Task";
import { Component, Input } from "@angular/core";
import { TreeState } from "@xo/services";

@Component({
    selector: 'xodos-apply-template',
    templateUrl: './template-list.component.html',
    styleUrls: ['./template-list.component.scss']
})

export class ApplyTemplateComponent extends Template {

    @Input() state: TreeState;

    selectedTemplate: Task;

    constructor() {
        super();
    }

    clicked(task: Task) {
        this.selectedTemplate = task;
        this.applyTemplate();
    }

    applyTemplate() {
        if (!this.selectedTemplate) {
            return;
        }
        (this.state.activeElement.XO_service as any).applyTemplateToTask(this.state.activeElement as Task, this.selectedTemplate.code).then(() => {
            this.state.activeElement.XO_service.updateChildren(this.state.activeElement).then(() => { });
        });
    }

}
