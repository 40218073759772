import { Injectable } from "@angular/core";
import { SystemUrlService, ConfigService } from "@xo/client-common";
import { Task } from '../../../task/Task';

@Injectable()
export class CommentUrlService extends SystemUrlService {
  constructor(system, configService: ConfigService) {
    super(system, "Xodos", "Comment", configService);
  }

  getTaskCommentUrl(id: number): string {
    return `${this.baseURL}?task=${id}`
  }

  getTypedTaskCommentUrl(task: Task): string {
    return `${this.baseURL}?task=${task.code}`
  }
}
