import { HttpClient } from "@angular/common/http";
import { CachingDataService, Transformator } from "@xo/services";
import { Group, GroupReference } from "./Group";
import { GroupUrlService } from "./group.url.service";

export class GroupTransformator extends Transformator<Group> {

  constructor(private system: string) {
    super();
  }

  async transformFromServer(rawGroup: any, localGroup?: Group): Promise<Group> {
    let result: Group = localGroup ? localGroup : new Group();
    result = Object.assign(result, rawGroup);
    result.parent = rawGroup.parent ? new GroupReference(rawGroup.parent.id, rawGroup.parent.label) : null;
    result["XO_SYSTEM"] = this.system; //TODO move to Transformator
    return result;
  }

  transformToServer(group: Group): any {
    let result: any = Object.assign({}, group);
    return result;
  }
}

export class CachingGroupService extends CachingDataService<Group> {
  public rootGroup: Group = null;
  public allGroups: Map<number, Group>;

  //TODO remove privete modifer from groupUrlService
  constructor(http: HttpClient, private groupUrlService: GroupUrlService, groupTransformator: GroupTransformator) {
    super(true, http, groupUrlService, groupTransformator, "id");
    this.allGroups = new Map();
  }

  async loadGoupsFromServer() {
    return this.list({}, "loadGoupsFromServer")
      .then((response: any) => {
        let groups: any[] = response ? response.page : [];
        groups.forEach((group: any) => {
          let currentGroup = this.getGroup(group.id)
          if (!currentGroup) {
            currentGroup = new Group();
          }
          currentGroup = Object.assign(currentGroup, group);
          if (!group.parent) {
            this.rootGroup = currentGroup;
          } else {
            currentGroup.parent.id = group.parent.id;
            let parent = this.getParentGroup(currentGroup);
            if (parent) {
              parent.addGroup(currentGroup, group.childIdex);
            }
          }
          this.allGroups.set(currentGroup.id, currentGroup);
        });
      });
  }

  public async getRootGroup(): Promise<Group> {
    if (this.rootGroup === null) {
      await this.loadGoupsFromServer();
    }
    return this.rootGroup;
  }

  public getGroup(groupId: number): Group {
    if (this.rootGroup === null) {
      this.loadGoupsFromServer().then(() => {
        return this.allGroups.get(groupId);
      });
    }
    return this.allGroups.get(groupId);
  }

  public getParentGroup(group: Group): Group {
    let parentId = group.parent.id;
    if (parentId == null) {
      return null;
    }
    return this.getGroup(parentId);
  }
}
