import { errorHandler } from "@xo/client-common";
import { HttpClient } from "@angular/common/http";
import { CommentUrlService } from "./comment.url.service";
import { Task } from '../../../task/Task';
import { Transformator, DataService } from '@xo/services';

export class CommentTransformator extends Transformator<Comment> {

    async transformFromServer(rawComment: any, localComment?: Comment): Promise<Comment> {
        let result: Comment = localComment ? localComment : new Comment();
        result = Object.assign(result, rawComment);
        return result;
    }

    transformToServer(comment: Comment): any {
        let result: any = Object.assign({}, comment);
        return result;
    }
}

export class CommentService extends DataService<Comment> {

    constructor(http: HttpClient, protected commentUrlService: CommentUrlService, commentTransformator: CommentTransformator) {
        super(http, commentUrlService, commentTransformator, "id");
    }


    getTaskComments(task: Task) {
        return this.http.get(this.commentUrlService.getTypedTaskCommentUrl(task), { withCredentials: true })
            .toPromise()
            .catch(errorHandler);
    }

    postTaskComment(task: Task, update: Object) {
        return this.http.post(this.commentUrlService.getTypedTaskCommentUrl(task), update, { withCredentials: true })
            .toPromise()
            .catch(errorHandler);
    }
}
