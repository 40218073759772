import { GroupType } from './../../../application-e2e/src/testVariables';
import { Component, ViewChild, EventEmitter, NgZone } from "@angular/core";
import { TaskElementService } from './task.service';
import { TreeComponent, modalEmitter, TabComponent, editElementLabel, Window, System } from '@xo/client-common';
import { Task, TaskType } from './Task';
import { CachingGroupService } from '../overview/group/component/group.service';
import { TaskFilterService, TaskFilter } from './taskFilter.service';
import { Config } from '../config/Config';
import { Key } from 'ts-keycode-enum';
import { Favicons } from '../common/services/favicons';
import { TaskUpdaterService } from "./task.updater.service";
import { TreeState, CachingDataService } from '@xo/services';

declare var config;

@Component({
  selector: "xodos-task-overview",
  styleUrls: [],
  templateUrl: "./task-overview.html",
})
export class TaskOverviewComponent extends Window {

  @ViewChild('tree') tree: TreeComponent;

  config = config;

  parameters: EventEmitter<any> = new EventEmitter<any>();

  rootElementsLoading: Promise<void>;
  private rootElementLoaderResolve: any;

  parent: string; //code of the current main task
  treeState: TreeState = new TreeState();

  keyFunctions = {};

  taskService: TaskElementService;
  taskFilterService: TaskFilterService;
  groupService: CachingGroupService;
  taskUpdateService;

  setEstimation: (x?) => void;

  system: System;

  constructor(public favicons: Favicons, private ngZone: NgZone,
    public tab: TabComponent) {
    super();
    this.system = this.tab.system;
    this.groupService = this.tab.getService(GroupType) as CachingGroupService;
    this.taskService = this.tab.getService("TaskElement") as TaskElementService;
  }


  reload() {
    super.reload();
  }

  async init(data: any) {
    if (!data) {
      data = {};
    }

    this.initialize();
    await this.getRootElements(data);
    await this.setTemplates();
    this.initFilters(data);
    this.revealRootChildren()
      .then(() => {
        this.taskFilterService.applyFilter()
        this.setInitialActiveElement(data);
      });
    this.keyFunctions = {
      modifier_none: {
        [Key.PlusSign]: this.setNextStatus.bind(this),
        [Key.Add]: this.setNextStatus.bind(this),
        [Key.Subtract]: this.setPreviousStatus.bind(this),
        [Key.Dash]: this.setPreviousStatus.bind(this),
        "_default_down": this.startEditing.bind(this),
      },
      modifier_ctrl: {
        [Key.Insert]: this.createReducedTypeChild.bind(this)
      },
      modifier_shift: {
        [Key.ForwardSlash]: this.setStausOnHold.bind(this),
      }
    }
    super.init(getParams(this.treeState, this.system.name, this.parent));
  }

  initialize() {
    this.favicons.activate('tree');

    let dataService = this.tab.getService(TaskType) as CachingDataService<Task>;

    this.taskService.groupService = this.groupService;
    this.taskService.treeState = this.treeState;
    this.taskUpdateService = new TaskUpdaterService(this.treeState, dataService, config.refreshInterval * 1000, this.ngZone);


    this.rootElementsLoading = new Promise(async (resolve) => {
      this.rootElementLoaderResolve = resolve;
    });
    this.taskFilterService = new TaskFilterService(this.treeState, this.taskService);
    this.initConfig();
    this.setEstimation = (value: string) => {
      if ((this.treeState.activeElement as Task).estimation = value) {
        (this.treeState.activeElement as Task).estimation = null;
      }
      (this.treeState.activeElement as Task).estimation = value;
    };
  }


  async setTemplates() {
    this.treeState.templates = (await this.taskService.dataService.list({ params: { isTemplate: true } }, "templateLoading", true)).page;
  }

  startEditing(state: TreeState, event: KeyboardEvent) {
    if (!(state.activeElement instanceof Task)) {
      return;
    }

    if (!event.ctrlKey && (event.keyCode >= 48 && event.keyCode <= 96 && event.keyCode !== 61 && event.keyCode !== 51)
      || event.keyCode == Key.Home
      || event.keyCode == Key.End
    ) {
      editElementLabel(state, event);
    }
  }

  toggleEdit() {
    if (this.treeState.activeElementEditing) {
      this.treeState.activeElementEditing = false;
    } else {
      this.startEditing(this.treeState, { keyCode: Key.Home } as any);
    }
  }

  deleteElement() {
    let parent = this.treeState.activeElement.XO_parent;
    if (!parent) {
      return;
    }
    let formerIndex = parent.XO_service.getChildren(parent).indexOf(this.treeState.activeElement);
    if (confirm("Biztosan törli az aktív elemet?")) {
      this.treeState.activeElement.XO_service.delete(this.treeState.activeElement).then(() => {
        parent.XO_service.updateChildren(parent).then(() => {
          let siblings = parent.XO_service.getChildren(parent);
          if (siblings.length == 0) {
            this.treeState.activeElement = parent;
          } else {
            if (formerIndex < siblings.length) {
              this.treeState.activeElement = siblings[formerIndex];
            } else {
              this.treeState.activeElement = siblings[siblings.length - 1];
            }
          }
        });
      });
    } else {
      return;
    }
  }

  openTaskDetails() {
    modalEmitter.emit({
      component: "Xodos/TaskDetailsComponent",
      data: {
        system: this.system.name,
        state: this.treeState,
        task: this.treeState.activeElement,
        config: this.treeState.config,
        taskService: this.taskService
      },
      system: this.tab.system,
    })
  }

  openFilters() {
    modalEmitter.emit({
      component: "Xodos/FilterComponent",
      data: {
        state: this.treeState,
        applyFilters: this.getFilterFunction(),
        groupService: this.groupService
      },
      system: this.tab.system,
    })
  }

  createNewChild(task: Task) {
    return (task.XO_service as TaskElementService).createChild(task).then((child) => {
      (task.XO_service as TaskElementService)
        .updateChildren(task)
        .then(() => {
          let children = task.XO_service.getChildren(task);
          this.treeState.activeElement = children.find(sibling => sibling.id == child.id)
          task.XO_ShowChildren = true;
          this.startEditing(this.treeState, { keyCode: Key.Home } as any);
        });
    });
  }

  initFilters(data) {
    console.log("before", this.treeState.filters);
    this.treeState.filters = Object.assign({
      root: null,
      brief: null,
      description: null,
      status: [],
      context: [],
      assignee: null,
      type: null,
      priority: null,
      dateRange: null,
      excludeStatus: [],
      showOnlyMatchedTasks: false,
      stat: {}
    }, data.filters) as TaskFilter;
    console.log("filters", data.filters, this.treeState.filters);
    if (!!this.treeState.filters.assignee) {
      this.treeState.filters.assignee = this.groupService.getGroup(this.treeState.filters.assignee.id);
    }
    if (!!this.treeState.filters.dateRange) {
      this.treeState.filters.dateRange = {
        start: new Date(this.treeState.filters.dateRange.start),
        end: new Date(this.treeState.filters.dateRange.end)
      };
    }
  }

  initConfig() {
    this.treeState.config = new Config();
  }

  async getRootElements(data) {
    if (!!data.newTemplate) {
      this.treeState.rootElements = [data.newTemplate];
    } else if (!!data.taskCode) {
      this.parent = data.taskCode;
      let params = { params: { parent: data.taskCode } };
      this.treeState.rootElements = (await this.taskService.dataService.list(params, "getRootElements", true) as any).page;
    } else {
      this.treeState.rootElements = await this.taskService.getRootElements();
    }
    if (this.treeState.rootElements.length == 0) {
      console.log("no rootElements found");
    }
    this.rootElementLoaderResolve();
  }

  setInitialActiveElement(data) {
    if (data.activeElement) {
      return;
    }
    this.treeState.activeElement = this.treeState.rootElements[0];
    this.treeState.activeElementEditing = false;
  }

  async revealRootChildren() {
    return Promise.all(
      this.treeState.rootElements.map(async rootElement => {
        rootElement.XO_ShowChildren = true;
        await rootElement.XO_service.updateChildren(rootElement);
        let rootChildren = rootElement.XO_service.getChildren(rootElement);
        return Promise.all(rootChildren.map(rootChild => rootChild.XO_service.updateChildren(rootChild)))
      })
    );
  }

  getFilterFunction() {
    return () => {
      this.updateParams(getParams(this.treeState, this.system.name, this.parent));
      this.taskFilterService.applyFilter();
    }
  }

  openConfigModal() {
    let modalPayload = {
      title: "Configuration",
      component: "Xodos/ConfigComponent",
      state: this.treeState,
      system: this.tab.system,
      taskService: this.taskService
    };
    modalEmitter.emit(modalPayload);
  }

  openLegendModal() {
    modalEmitter.emit({
      component: "Xodos/LegendComponent",
    });
  }

  setNextStatus(state: TreeState) {
    (state.activeElement.XO_service as any).setNextStatus(state);
  }

  setPreviousStatus(state: TreeState) {
    (state.activeElement.XO_service as any).setPreviousStatus(state);
  }

  setStausOnHold(state: TreeState) {
    (state.activeElement.XO_service as any).setStatusToOnHold(state);
  }

  createReducedTypeChild(state: TreeState) {
    let parent = state.activeElement as Task;
    parent.XO_ShowChildren = true;
    (parent.XO_service as any).createChild(parent, "task", true).then((newChild) => {
      parent.XO_service
        .updateChildren(parent)
        .then(() => {
          let children = parent.XO_service.getChildren(parent);
          state.activeElement = children.find(sibling => sibling.id == newChild.id);
        });
    });
  }

}

function getParams(treeState: TreeState, system: string, taskCode: string) {
  return {
    system,
    taskCode,
    filters: sanitazeFilters(treeState.filters)
  };
}

function sanitazeFilters(filters) {
  let filterParams = Object.assign({}, filters);
  Object.keys(filterParams).forEach(key => {
    if (filterParams[key] === null || filterParams[key] === "" || filterParams[key].length === 0 || filterParams[key] === false) {
      delete filterParams[key]
    };
  })
  delete filterParams.stat;
  return filterParams;
}
