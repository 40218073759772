<div>
    <label>MassCreate</label>
    <textarea [(ngModel)]="taskText" style="height: 200px;"></textarea>
</div>
<div>
    <span>
        ChildPrefix
        <select [(ngModel)]="childPrefix">
            <option *ngFor="let item of childPrefixValues | keyvalue" [value]="item.value">
                {{item.key}}
            </option>
        </select>
    </span>
    <span>
        Status Indicator
        <input [(ngModel)]="statusIndicator" type="checkbox" />
    </span>
</div>
<button class="btn" (click)="createAll()">Create all</button>