import { Component, ComponentRef } from '@angular/core';
import { Favicons } from '../common/services/favicons';
import { Task } from '../task/Task';
import { WorklogService } from './worklog.service';
import { IModalDialog, IModalDialogOptions } from '@preeco-privacy/ngx-modal-dialog';

@Component({
  selector: 'xodos-worklog',
  templateUrl: './worklog.component.html',
  styleUrls: ['./worklog.component.scss']
})
export class WorklogComponent implements IModalDialog {
  task: Task;
  duration: string = '';
  comment: string = '';
  statusChangeId: number;

  options: any;

  WorklogService: WorklogService;

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    this.options = options;
    this.task = this.options.data.data.task;
    if (!!options.data.data.comment) {
      this.comment = this.comment + ' # ' + this.options.data.data.comment;
    };
    this.WorklogService = this.options.data.data.worklogService;
  }

  constructor(public favicons: Favicons) {
    this.favicons.activate('worklog');
  }

  cancel() {
    this.options.closeDialogSubject.next();
  }


  saveNewWorklog() {
    let worklog = this.WorklogService.createWorklog(this.task, this.duration, this.comment, this.statusChangeId);
    let payLoad = {
      duration: worklog.duration,
      comment: worklog.comment,
      task: { code: this.task.code },
      statusChange: worklog.statusChangeId ? { id: worklog.statusChangeId } : null
    };

    this.WorklogService.postWorklogHttp(payLoad)
      .then((response: any) => {
        worklog.id = response.id;
        worklog.created = new Date(response.created);
        worklog.user = response.user;
        WorklogService.allWorklogs.set(worklog.id, worklog);
        this.cancel();
      })
      .catch((error) => {
      });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
