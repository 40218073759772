import { Injectable } from "@angular/core";
import { MenuItem, MenuService, ConfigService, CurrentUserContainer, PERMISSION_MENU, System } from '@xo/client-common';
import { TaskType } from 'projects/xodos/src/task/Task';
import { GroupType } from 'projects/xodos/src/overview/group/component/Group';

export const XODOS = "Xodos";

@Injectable()
export class XodosMenuService extends MenuService {

    constructor(protected configService: ConfigService, currentUserContainer: CurrentUserContainer) {
        super(configService, currentUserContainer);
    }


    async getModules(): Promise<MenuItem[]> {
        this.menu = [
            ...(await this.createXodosMenus()),
            this.createProfileMenu()
        ].filter(menuItem => !!menuItem);
        return this.menu; 1
    }

    createXodosMenus() {
        return this.createModuleMenu(XODOS, async (multipleSystems: boolean, system: System) => {
            return [
                this.createMenu(
                    (multipleSystems ? system.name + " / " : "") + "Xodos",
                    this.createMenuItem(system, XODOS, TaskType, PERMISSION_MENU, "Overview", XODOS + "/TaskOverviewComponent", null, false),
                    this.createMenuItem(system, XODOS, TaskType, PERMISSION_MENU, "Feed", XODOS + "/FeedComponent"),
                    this.createMenuItem(system, XODOS, GroupType, PERMISSION_MENU, "Group", XODOS + "/GroupListWindowComponent"),
                    this.createMenuItem(system, XODOS, TaskType, PERMISSION_MENU, "Gráf", XODOS + "/TaskGraphComponent"),
                )
            ]
        })
    }
}
