import { GroupType } from './../../../../application-e2e/src/testVariables';
import { TaskElementService } from '../task.service';
import { GraphState, LinkService, TabComp, TabComponent } from '@xo/client-common';
import { ViewChild, OnDestroy, Component } from '@angular/core';
import { CachingGroupService } from '../../overview/group/component/group.service';


@Component({
    selector: "task-graph",
    templateUrl: "./task-graph.html"
})
export class TaskGraphComponent implements TabComp, OnDestroy {

    @ViewChild('graph') graph;

    graphState: GraphState;
    taskElementService: TaskElementService
    linkService: LinkService;
    system: string;

    constructor(private tab: TabComponent) {

    }

    async init(data) {
        this.linkService = new LinkService();
        this.graphState = new GraphState(this.linkService);
        let groupService = this.tab.getService(GroupType) as CachingGroupService;
        this.taskElementService = this.tab.getService("TaskElement") as TaskElementService;

        this.taskElementService.groupService = groupService;
        this.taskElementService.graphState = this.graphState;

        this.graphState.addLoader(async (graphState: GraphState) => {
            let tasks = (await this.taskElementService.dataService.list(
                { params: { parent: null } },
                "task-graph-init")).page;
            graphState.addElements(tasks);
        });
        // TODO   this.graphState.services[TaskType] = this.taskElementService.dataService;
    }

    reload() {

    }

    async ngOnDestroy() {
        this.graphState.sys.stop();
    }

}
