import { Component, Input } from '@angular/core';
import { Task } from '../../task/Task';

@Component({
  selector: 'xodos-worklog-list',
  templateUrl: './worklog-list.component.html',
  styleUrls: ['./worklog-list.component.scss']
})
export class WorklogListComponent {

  @Input() task: Task;

}
