import { Task } from "../../../task/Task";
import { EventEmitter } from '@angular/core';

export let closeTemplate = new EventEmitter<void>();

export abstract class Template {

    constructor() {
    }

    abstract clicked(task: Task);

    stopPropagation(event) {
        event.stopPropagation();
    }
}