<div class="list-buttons">
    <button [disabled]="allRecordsShown" id="showAllRecords" class="btn" (click)="showAllRecords()">Összes
        mutatása</button>
    <button [disabled]="!allowLeftButton()" id="pageLeft" (click)="pageLeft()" class="btn">&lt;&lt;</button>
    <span class="currentPage">{{pageIndex}}.</span> oldal
    <button [disabled]="!allowRightButton()" id="pageRight" (click)="pageRight()" class="btn">&gt;&gt;</button>
    <span class="userMessage" *ngIf="!!loading">Betöltés folyamatban...</span>
</div>

<table>
    <thead>
        <tr xo-table-header-row [columnInfo]="Group.columnInfo"></tr>
        <tr>
            <th xo-table-filter-input fieldName="name" [parent]="this"></th>
            <th xo-table-filter-select fieldName="parent" [parent]="this" [selectValues]="groupsForParent"></th>
            <th xo-table-filter-input fieldName="childIndex" [parent]="this"></th>
            <th xo-table-filter-input fieldName="code" [parent]="this"></th>
            <th xo-table-filter-select fieldName="color" [parent]="this" [selectValues]="colorSelectValues"></th>
            <th xo-table-filter-input fieldName="weight" [parent]="this"></th>
        </tr>
    </thead>
    <tbody *ngIf="!!objects">
        <tr *ngFor="let group of objects" (click)="clickHandler(group)" [ngClass]="getClasses(group)">
            <td xo-table-data-string [object]="group" class="name" fieldName="name"></td>
            <td xo-table-data-link [object]="group" class="parent" fieldName="parent" componentName="Xodos/GroupPage">
            </td>
            <td xo-table-data-string [object]="group" class="childIndex" fieldName="childIndex"></td>
            <td xo-table-data-string [object]="group" class="code" fieldName="code"></td>
            <td xo-table-data-string [object]="group" class="color" fieldName="color"></td>
            <td xo-table-data-string [object]="group" class="weight" fieldName="weight"></td>
        </tr>
        <tr xo-table-summary-selector [summary]="summary" [summaryInfo]="Group.columnInfo"
            [onUpdate]="createUpdateFunction()"></tr>
        <tr xo-table-summary [summary]="summary" [summaryInfo]="Group.columnInfo"></tr>
    </tbody>
</table>
<div *ngIf="allowCreate">
    <button (click)="clickHandler()" class="createButton btn btn-primary">create</button>
</div>
