import { IModalDialog, IModalDialogOptions } from '@preeco-privacy/ngx-modal-dialog';
import { Component, Input } from '@angular/core';
import { Statuses, taskStatusOrder } from '../../common/model/Status';
import { Contexts, ContextLabels } from '../../common/model/Context';
import { Types, TypeLabels } from '../../common/model/Type';
import { Priorities, PriorityLabels } from '../../common/model/Priority';
import { Group } from '../group/component/Group';
import { CachingGroupService } from '../group/component/group.service';
import { TreeState } from '@xo/services';


@Component({
  selector: 'xodos-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements IModalDialog {
  statusOrder: string[];
  Statuses: Object;
  Contexts: string[];
  ContextLabels: Object;
  Types: string[];
  TypeLabels: Object;
  Priorities: string[];
  PriorityLabels: Object;
  _model: any;

  @Input() state: TreeState;
  @Input() applyFilters: () => void;
  @Input() groupService: CachingGroupService;

  public showOnlyMatchedTasks: boolean = false;

  constructor() {
    this.Contexts = Contexts;
    this.statusOrder = taskStatusOrder;
    this.Statuses = Statuses;
    this.ContextLabels = ContextLabels;
    this.Types = Types;
    this.TypeLabels = TypeLabels;
    this.Priorities = Priorities;
    this.PriorityLabels = PriorityLabels;
  }

  dialogInit(reference: import("@angular/core").ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    this.state = options.data.data.state;
    this.applyFilters = options.data.data.applyFilters;
    this.groupService = options.data.data.groupService;
    console.log("filter in dialog");
  }

  ngAfterContentInit(): void {
    if (!!this.state.filters.dateRange) {
      this.model = [this.state.filters.dateRange.start, this.state.filters.dateRange.end];
    }
  }

  public displayFilterComp(): boolean {
    return !!this.state.filters;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  set model(date: any) {
    console.log(this._model, date)
    this._model = date;
    if (date !== null) {
      this.state.filters.dateRange = {
        start: date[0],
        end: date[1],
      };
    } else {
      this.state.filters.dateRange = null;
    }
    this.applyFilters();
  }

  get model() {
    return this._model;
  }

  setExcludedStatusFilter(event) {
    this.applyFilters();
    event.stopPropagation();
  }

  setBriefFilter(event) {
    this.applyFilters();
    event.stopPropagation();
  }

  setDescriptionFilter(event) {
    this.applyFilters();
    event.stopPropagation();
  }

  setShowOnlyMatchedTasks(event) {
    this.state.filters.showOnlyMatchedTasks = event.target.checked;
    this.applyFilters();
    event.stopPropagation();
  }

  setStatusFilter(event) {
    this.applyFilters();
    event.stopPropagation();
  }

  setContextFilter(event) {
    this.applyFilters();
    event.stopPropagation();
  }

  setTypeFilter(event) {
    this.applyFilters();
    event.stopPropagation();
  }

  setPriorityFilter(event) {
    this.applyFilters();
    event.stopPropagation();
  }

  setGroupFilter(group: Group) {
    this.state.filters.assignee = group;
    this.applyFilters();
  }

  cancelBubble(event) {
    event.stopPropagation();
  }

}
