import { ObjectReference, Node, ColumnInfo } from "@xo/services";

const colorValues = new Map<string, string>();
colorValues.set("green", "Green");
colorValues.set("blue", "Blue");
colorValues.set("smoke", "Smoke");

export class GroupReference extends ObjectReference {
    constructor(id?: number, label?: string) {
        super(id, label, "id");
    }
}

export let GroupType = "Group";

export class Group extends Node {

    constructor() {
        super(GroupType, "Xodos/SimpleGroupComponent");
    }

    get label() {
        return this.name;
    }

    set label(label) {
        this.name = label;
    }

    get classes() {
        return this.color;
    }

    set classes(c) { }

    public static colorKeys: string[] = Array.from(colorValues.keys());
    public static getColorValues(key: string): string {
        return colorValues.get(key);
    }

    public name: string;
    public id: number;
    public parent: GroupReference;
    public groups: Group[] = new Array<Group>();
    public weight: number = 1;
    public color: string = '';
    public code: string = '';
    childIndex: number;

    refreshChildrenIndex() {
        for (let i = 0; i < this.groups.length; i++) {
            this.groups[i].childIndex = i;
            if (this.groups[i].groups.length > 0) {
                this.refreshChildrenIndex.call(this.groups[i]);
            }
        }
    }

    public addGroup(group: Group, index?: number): void {
        if (index && !this.getSubGroupById(group.id)) {
            this.groups.splice(index, 0, group);
            group.parent.id = this.id;
        } else {
            if (!this.getSubGroupById(group.id)) {
                this.groups.push(group);
                group.parent.id = this.id;
            }
        }
        this.refreshChildrenIndex();
    }

    public getSubGroups(): Group[] {
        return this.groups;
    }

    getSubGroupById(id: number): Group {
        for (let i = 0; i < this.groups.length; i++) {
            let currentGroup = this.groups[i];
            if (currentGroup.id == id) {
                return currentGroup;
            }
        }
        return null;
    }
    removeSubGroup(group: Group): void {
        let index = this.groups.indexOf(group, 0);
        if (index >= 0) {
            this.groups.splice(index, 1);
        }
        this.refreshChildrenIndex();
    }

    public static columnInfo = [
        new ColumnInfo("Name", "name", "string", "None"),
        new ColumnInfo("Parent Group", "parent", "stringLink", "None"),
        new ColumnInfo("Chilindex", "childIndex", "number", "None"),
        new ColumnInfo("Code", "code", "string", "None"),
        new ColumnInfo("Color", "color", "stringSelect", "None"),
        new ColumnInfo("Wieght", "weight", "number", "None"),
    ];
}
