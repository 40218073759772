import { STATUS_READY, STATUS_DONE, STATUS_CLOSED, Statuses } from "../common/model/Status";
import { Component } from "@angular/core";
import { FeedGroupContainerService, feedGroupEmitter } from "./feedGroupContainer.service";
import { Task, TaskType } from '../task/Task';
import { CachingTaskService } from '../task/task.service';
import { Window, getAMonthFromNow, ONE_DAY, ONE_HOUR, ONE_WEEK, TabComponent } from '@xo/client-common';
import { CachingGroupService } from '../overview/group/component/group.service';
import { transformTimestampToServer } from '@xo/services';
import { GroupType } from "../overview/group/component/Group";



@Component({
    selector: "xodos-feed",
    templateUrl: './feed.component.html',
    styleUrls: ['./feed.component.scss']
})
export class FeedComponent extends Window {

    tasks: Task[] = [];

    range;
    ranges = {
        '1 hour': new Date(new Date().getTime() - ONE_HOUR),
        '1 day': new Date(new Date().getTime() - ONE_DAY),
        '1 week': new Date(new Date().getTime() - ONE_WEEK),
        '1 month': getAMonthFromNow(),
    };

    status;
    statuses = [
        STATUS_READY,
        STATUS_DONE,
        STATUS_CLOSED
    ];
    Statuses = Statuses;

    groups = [];

    system: string;

    groupService: CachingGroupService;
    taskService: CachingTaskService;

    constructor(tab: TabComponent, private feedGroupContainerService: FeedGroupContainerService) {
        super();
        this.setDefaultFilters();
        this.groupService = tab.getService(GroupType) as CachingGroupService;
        this.taskService = tab.getService(TaskType) as CachingTaskService;
    }

    init(data) {
        if (!data || !data || !data.system) {
            throw new Error("Please provide system to FeedComponent component in menu");
        }
        this.system = data.system.name;

        super.init(data);
        if (!!data.range) {
            this.range = data.range;
        }
        if (!!data.status) {
            this.status = data.status;
        }
        if (!!data.group) {
            this.feedGroupContainerService.feedGroup = !!data.group ? { id: data.group } as any : null;
        }

        this.updateTasks();
        feedGroupEmitter.subscribe(() => {
            this.updateTasks();
        });
    }

    async updateTasks() {
        this.updateParams({
            system: this.system,
            range: this.range,
            status: this.status,
            group: this.feedGroupContainerService.feedGroup ? this.feedGroupContainerService.feedGroup.id : null
        });
        this.tasks = (await this.getFeed() as any);
    }


    feedItemClicked(task: Task) { //TODO fix
        let newUrl = `/${task.parentLabel}/filter/brief:${task.brief}/unm:true`;
        (window.location as any) = newUrl;
    }

    setDefaultFilters() {
        this.range = '1 hour';
        this.status = this.statuses[0];
    }

    getFeed() {
        let params = {
            feedTime: transformTimestampToServer(this.ranges[this.range]),
            feedStatus: this.status,
            feedGroup: this.feedGroupContainerService.feedGroup ? this.feedGroupContainerService.feedGroup.id : null
        }

        return this.taskService.getFeedTasks(params).then((response) => {
            console.log("reesponse", response);
            if (!response) {
                return [];
            }
            let feedTasks = response;
            return feedTasks;

        });
    }
}
