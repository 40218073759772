import { TaskElementService } from './task.service';
import { TreeState } from "@xo/services";
import { Task } from './Task';
import { Group } from '../overview/group/component/Group';
import { SUBTASK, COMPONENT, FEATURE } from '../common/model/Type';
import { SubTaskTaskStatusMap } from '../common/model/Status';

export interface TaskFilter {
    root: string;
    brief: string;
    description: string;
    status: string[];
    excludeStatus: string[];
    context: string[];
    assignee: Group;
    type: string;
    priority: string;
    dateRange: any;
    showOnlyMatchedTasks: boolean;
}

export class TaskFilterService {

    private filters = [
        this.filterBrief,
        this.filterDescription,
        this.filterStatus,
        this.filterContext,
        this.filterType,
        this.filterPriority,
        this.filterGroup,
        this.filterDeadline,
        this.filterExcludeStatus
    ];

    service: TaskElementService;
    state: TreeState;

    constructor(state, service) {
        this.state = state;
        this.service = service;
    }

    applyFilter() {
        this.state.filters.stat = {
            total: 0,
            matched: 0,
            visible: 0,
        };
        if (this.allFiltersEmpty(this.state.filters)) {
            this.service.dataService.objects.forEach(task => {
                task.matched = false;
                task.hidden = false;
                this.state.filters.stat.total++;
                this.state.filters.stat.matched++;
                this.state.filters.stat.visible++;
            });
            return;
        }
        this.service.dataService.objects.forEach(task => {
            task.matched = this.checkTask(this.state.filters, task);
            task.hidden = this.state.filters.showOnlyMatchedTasks && !task.matched;
            this.state.filters.stat.total++;
            if (task.matched) {
                this.state.filters.stat.matched++;
            }
            if (!task.hidden) {
                this.state.filters.stat.visible++;
            }
        });
        if (this.state.filters.showOnlyMatchedTasks) {
            this.revealParents();
        }
    }

    private revealParents() {
        this.service.dataService.objects.forEach(task => {
            if (!task.hidden) {
                this.revealAllParents(task.parentCode);
            }
        });
    }

    private revealAllParents(taskCode: string) {
        let task = this.service.dataService.get(taskCode);
        if (!task) {
            return;
        }
        task.hidden = false;
        this.state.filters.stat.visible++;
        this.revealAllParents(task.parentCode);
    }

    allFiltersEmpty(filters: TaskFilter) {
        return (!filters.assignee) &&
            (!filters.brief || filters.brief == "") &&
            (!filters.context || filters.context.length == 0) &&
            (!filters.dateRange) &&
            (!filters.description || filters.description == "") &&
            (!filters.priority) &&
            (!filters.status || filters.status.length == 0) &&
            (!filters.excludeStatus || filters.excludeStatus.length == 0) &&
            (!filters.type)
    }

    private checkTask(taskFilter: TaskFilter, task: Task) {
        return this.filters.reduce((result, filter) => {
            return result && filter.call(this, taskFilter, task);
        }, true);
    }

    private filterBrief(filter: TaskFilter, task: Task) {
        return (!filter.brief || (task.brief.indexOf(filter.brief) >= 0));
    }

    private filterDescription(filter: TaskFilter, task: Task) {
        return (!filter.description || (!!task.description && task.description.indexOf(filter.description) >= 0));
    }


    private filterStatus(filter: TaskFilter, task: Task) {
        let statuses = filter.status;
        if (statuses === undefined) {
            return true;
        }
        if (task.type.code === SUBTASK || task.type.code === COMPONENT || task.type.code === FEATURE) {
            return (statuses.length === 0 || (statuses.indexOf(SubTaskTaskStatusMap[task.status.id].id) >= 0));
        } else {
            return (statuses.length === 0 || (statuses.indexOf(task.status.id) >= 0));
        }
    }

    private filterContext(filter: TaskFilter, task: Task) {
        let contexts = filter.context;
        if (contexts === undefined) {
            return true;
        }
        return (contexts.length === 0 || (contexts.indexOf(task.context) >= 0))
    }

    private filterType(filter: TaskFilter, task: Task) {
        return (!filter.type || (task.type.code === filter.type));
    }

    private filterGroup(filter: TaskFilter, task: Task) {
        return (!filter.assignee || this.filterAssignee(filter.assignee, task));
    }

    private filterDeadline(filter: TaskFilter, task: Task) {
        return (!filter.dateRange || !filter.dateRange.start || !filter.dateRange.end || ((filter.dateRange.start <= task.deadline) && (filter.dateRange.end >= task.deadline)))
    }

    private filterAssignee(filterGroup: Group, task: Task): boolean {
        if (!task.assignee) {
            return false;
        }
        if (task.assignee.id === filterGroup.id) {
            return true;
        }
        return this.isAssignedToSubGroup(filterGroup, task);
    }

    private filterPriority(filter: TaskFilter, task: Task) {
        return (!filter.priority || (task.priority === filter.priority));
    }

    private isAssignedToSubGroup(group: Group, task: Task): boolean {
        let subGroups = group.getSubGroups();
        return subGroups.reduce((prevResult, subGroup) => {
            return prevResult || task.assignee.id === subGroup.id || this.isAssignedToSubGroup(subGroup, task);
        }, false);
    }

    private filterExcludeStatus(filter: TaskFilter, task: Task) {
        let statuses = filter.excludeStatus;
        if (statuses === undefined) {
            return true;
        }
        if (statuses.length === 0) {
            return true;
        }

        let taskStatus = task.status.id;
        if (task.type.code === SUBTASK) {
            taskStatus = SubTaskTaskStatusMap[task.status.id].id;
        }
        return statuses.indexOf(taskStatus) < 0;
    }
}
