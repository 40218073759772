import { Estimations } from '../estimation/Estimation';
import { Styles } from '../common/model/Style';

export class Config {
  public estimationMethod: string = Estimations[0];
  public escalateOnHoldStatus: boolean = true;
  public escalateDoneStatus: boolean = true;
  public escalateClosedStatus: boolean = true;
  public typeAppends: boolean = true;
  public worklogScreenOnStatusChange: boolean = false;
  public iconTypes: string = Styles[0];
  public escalateReadyStatus: boolean = true;
}
