import { Component, Input } from '@angular/core';

@Component({
  selector: '[xodos-history-item]',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss']
})
export class HistoryItemComponent{

  @Input() historyItem: any;

  constructor() { }


}
