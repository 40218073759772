export let storypoints = ['1', '2', '3', '5', '8', '13', '21'];
export let durations = ['15m', '30m', '1h', '2h', '4h', '1d', '2d'];

export const ESTIMATION_STORYPOINTS = 'storypoints';
export const ESTIMATION_DURATIONS = 'durations';

export const Estimations = [ESTIMATION_STORYPOINTS, ESTIMATION_DURATIONS];

export const EstimationValues = {};
EstimationValues[ESTIMATION_STORYPOINTS] = storypoints;
EstimationValues[ESTIMATION_DURATIONS] = durations;


export const EstimationLabels = {};
EstimationLabels[ESTIMATION_STORYPOINTS] = 'Story Points';
EstimationLabels[ESTIMATION_DURATIONS] = 'Durations';
