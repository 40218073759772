import { Injectable } from "@angular/core";
import { SystemUrlService, ConfigService } from "@xo/client-common";

@Injectable()
export class WorklogUrlService extends SystemUrlService {
    constructor(system, configService: ConfigService) {
        super(system, "Xodos", "Worklog", configService);
    }

    getWorklogUrl(id: number, sth?: string): string {
        return `${this.baseURL}${sth}${id}`;
    }
}
