<div>
    <h2>
        Új task
    </h2>
    <div class="right-buttons">
        <button class="btn btn-default" id="cancelButton" (click)="cancel()">MÃ©gsem</button>
        <button class="btn btn-primary" id="saveButton" (click)="save()" type="submit">MentÃ©s</button>
    </div>

    <div class="simplecolumn4">
        <label>Brief: </label>
        <input id="brief"
            [(ngModel)]="task.brief"
            type="text"
            title="brief">
    </div>  

    <div class="simplecolumn4">
        <label>Kapcsolat címke: </label>
        <input id="linkText"
            [(ngModel)]="relationshipName"
            type="text"
            title="relationshipName">
    </div>
</div>