import { SystemUrlService, ConfigService } from "@xo/client-common";
import { Injectable } from '@angular/core';
import { Task } from "../../../task/Task";

@Injectable()
export class StatusChangeUrlService extends SystemUrlService {

  constructor(system, configService: ConfigService) {
    super(system, "Xodos", "StatusChange", configService);
  }

  getTypedTaskStatusUrl(task: Task): string {
    return `${this.baseURL}?task=${task.code}`
  }

}
