import { EventEmitter } from '@angular/core';
import { Task } from '../../task/Task';

export interface TaskChange {
    task: Task;
    fieldName: string;
    newValue: any;
}

export interface ConfigChange {
    fieldName: string;
    newValue: any;
}

export let taskChanged: EventEmitter<TaskChange> = new EventEmitter<TaskChange>();
