import { Input, OnInit, Component } from '@angular/core';
import { CachingGroupService } from './component/group.service';
import { Group } from './component/Group';

export const FEED = 'feedGroup';
export const SET = 'setGroup';
export const FILTER = 'filterGroup';

@Component({ template: "" })
export abstract class AbstractGroupComponent implements OnInit {
  @Input() group?: Group;

  @Input()
  public groupService: CachingGroupService;
  public groupType: string; //TODO must set in children, this class shouldnt be a component

  public sumWeight: number = 1;

  task;
  filterFunction;
  state;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.group) {
      this.group = new Group();
      this.group.name = "Loading Groups...";
      this.groupService.getRootGroup()
        .then((rootGroup) => {
          if (!!rootGroup) {
            console.log("getting group", rootGroup);
            this.group = rootGroup;
            this.recountWeight();
          }
        });
    }
    this.recountWeight();
  }

  recountWeight() {
    let subGroups = this.group.getSubGroups();
    this.sumWeight = subGroups.reduce((sum, current) => {
      return sum + current.weight;
    }, 0);
  }

  setGroup(event: Event): void {
    this.clicked(this.group, event);
  }

  getClasses() {
    let classes = {
      'container': true,
      'selected': this.isSelected()
    };
    classes[this.group.color] = true;
    return classes;
  }

  abstract clicked(group: Group, event: Event);

  abstract isSelected();
}
