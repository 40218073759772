<div style="width: 100%; height: 100vh;" *ngIf="!!treeState.rootElements">
    <div style="width: 20%; float: left; max-width: 300px;">
        <xodos-filter class="filterPanel" *ngIf="!!treeState.filters && !!groupService" [state]="treeState"
            [applyFilters]="getFilterFunction()" [groupService]="groupService"></xodos-filter>
    </div>
    <div style="width: 20%; float: right" class="detailsPanel">
        <xodos-task-details [task]="treeState.activeElement" [config]="treeState.config" [state]="treeState"
            [taskService]="taskService.dataService" [system]="system"></xodos-task-details>
    </div>
    <div class="estimation_and_groups_and_buttons">
        <button *ngIf="config.refreshInterval == 0" style="float: right" class="btn" id="refresh"
            (click)="taskUpdateService.refresh()">R</button>
        <button style="float: right" class="btn" id="openConfig" (click)="openConfigModal()">Config</button>
        <button style="float: right" class="btn" id="openLegend" (click)="openLegendModal()">Legend</button>
        <xodos-set-group class="overviewSetGroup" style="width: 25%; float: left;" [task]="treeState.activeElement"
            *ngIf="!!treeState.activeElement" [groupService]="groupService"></xodos-set-group>
        <xodos-estimation class="overviewEstimation" style="width: 25%; float: left;" [task]="treeState.activeElement"
            [config]="treeState.config" [clickHandler]="setEstimation"></xodos-estimation>
    </div>
    <div class="mobileButtons" style="display: none;">
        <button class="btn createNewChildButton" (click)="createNewChild(treeState.activeElement)">New Child</button>
        <button class="btn toggleEditButton" (click)="toggleEdit()">Start/Stop Edit</button>
        <button class="btn openTaskDetails" (click)="openTaskDetails()">Open TaskDetails</button>
        <button class="btn openFilters" (click)="openFilters()">Open Filters</button>
        <button class="btn deleteElement" (click)="deleteElement()">Delete Element</button>
    </div>
    <div style="width: 60%; float: left">
        <xo-tree [state]="treeState" [extraKeyHandlers]="keyFunctions" [active]="tab.active"></xo-tree>
    </div>
</div>
