import { errorHandler } from "@xo/client-common";
import { Worklog } from "./Worklog";
import { Task } from "../task/Task";
import { HttpClient } from '@angular/common/http';
import { WorklogUrlService } from './worklog.url.service';
import { Transformator, DataService } from '@xo/services';

export class WorklogTransformator extends Transformator<Worklog> {
  async transformFromServer(rawWorklog: any, localWorklog?: Worklog): Promise<Worklog> {
    let result: Worklog = localWorklog ? localWorklog : new Worklog();
    result = Object.assign(result, rawWorklog);
    return result;
  }

  transformToServer(worklog: Worklog): any {
    let result: any = Object.assign({}, worklog);
    return result;
  }

}

export class WorklogService extends DataService<Worklog> {

  public static allWorklogs = new Map<number, Worklog>();
  private taskCode: string;

  constructor(http: HttpClient, protected worklogUrlService: WorklogUrlService, worklogTranformator: WorklogTransformator) {
    super(http, worklogUrlService, worklogTranformator, "id");
  }

  public createWorklog(task: Task, duration: string, comment: string, statusChangeId?: number): Worklog {
    let wl = new Worklog(this.taskCode);
    wl.user = null;
    wl.created = new Date();
    wl.duration = duration;
    wl.comment = comment;
    wl.statusChangeId = statusChangeId;
    task.addWorklog(wl);
    return wl;
  }

  getWorklog(id: number) {
    let wl = WorklogService.allWorklogs.get(id);
    return wl;
  }

  getWorklogHttp(id: number, sth?: string) {
    return this.http.get(this.worklogUrlService.getWorklogUrl(id, sth), { withCredentials: true })
      .toPromise()
      .catch(errorHandler);
  }

  postWorklogHttp(update: Object) {
    return this.http.post(this.worklogUrlService.getListUrl(), update, { withCredentials: true })
      .toPromise()
      .catch(errorHandler);
  }

}
