import { Component, Input } from '@angular/core';
import { Template, closeTemplate } from './template.component';
import { Task } from '../../../task/Task';
import { openTab } from '@xo/client-common';
import { TreeState } from '@xo/services';

@Component({
  selector: 'xodos-template-config',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateConfigComponent extends Template {

  @Input() state: TreeState;
  @Input() system: string;


  constructor() {
    super();
  }

  clicked(task: Task) {
    closeTemplate.emit();
    openTab.emit({
      component: "Xodos/TaskOverviewComponent",
      data: { newTemplate: task },
      label: task.brief,
      system: this.system
    });
  }
}
