import { Status, taskStatusOrder, subTaskStatusOrder, STATUS_CLOSED, STATUS_CANCELLED, SUBTASK_STATUS_READY, convertSimpleToComplex, convertComplexToSimple, STATUS_ON_HOLD, SUBTASK_STATUS_ON_HOLD } from "./Status";

export const PROJECT = 'PROJECT';
export const COMPONENT = 'COMPONENT'
export const TASK = 'TASK';
export const FEATURE = 'FEATURE'
export const SUBTASK = 'SUBTASK';
export const BUG = 'BUG';

export function getNewStatus(oldType, newType, oldStatus) {
    if ((simpleTypes.indexOf(oldType) >= 0) && (simpleTypes.indexOf(newType) >= 0)) return oldStatus;
    if ((complexTypes.indexOf(oldType) >= 0) && (complexTypes.indexOf(newType) >= 0)) return oldStatus;

    if ((simpleTypes.indexOf(oldType) >= 0) && (complexTypes.indexOf(newType) >= 0)) return convertSimpleToComplex(oldStatus);
    if ((complexTypes.indexOf(oldType) >= 0) && (simpleTypes.indexOf(newType) >= 0)) return convertComplexToSimple(oldStatus);
}

export let simpleTypes = [
    COMPONENT,
    FEATURE,
    SUBTASK
];

export let complexTypes = [
    PROJECT,
    TASK,
    BUG
]

export let Types = [
    'PROJECT',
    'COMPONENT',
    'FEATURE',
    'TASK',
    'SUBTASK',
    'BUG'
];

export let TypeLabels = {}
TypeLabels[PROJECT] = "Project";
TypeLabels[COMPONENT] = "Component";
TypeLabels[FEATURE] = "Feature";
TypeLabels[TASK] = "Task";
TypeLabels[SUBTASK] = "Subtask";
TypeLabels[BUG] = "Bug";

export class Type {

    constructor(public code?: string, public iconClass?: string){
       if(code){
           if([PROJECT, TASK, BUG].indexOf(code) >= 0){
               //TODO subclassing
               this.statusGroup = taskStatusOrder;
           } else {
               //TODO subclassing
               this.statusGroup = subTaskStatusOrder;
           }
       }
    }

    statusGroup: Status[];

    nextStatus(status: Status){
        if (status.id === STATUS_CLOSED || status.id === STATUS_CANCELLED || status.id === SUBTASK_STATUS_READY) {
            return status;
        } else {
            return this.statusGroup[this.statusGroup.indexOf(status) + 1];
        }
    }

    prevStatus(status: Status){
        if (status.id === STATUS_ON_HOLD || status.id === STATUS_CANCELLED || status.id === SUBTASK_STATUS_ON_HOLD) {
            return status;
        } else {
            return this.statusGroup[this.statusGroup.indexOf(status) - 1];
        }
    }
}

export let types = {
    PROJECT: new Type(PROJECT, PROJECT),
    COMPONENT: new Type(COMPONENT, COMPONENT),
    FEATURE: new Type(FEATURE, FEATURE),
    TASK: new Type(TASK, TASK),
    SUBTASK: new Type(SUBTASK, SUBTASK),
    BUG: new Type(BUG, BUG)
}

export let typeArray = Object.keys(types).map(key=>types[key]);
