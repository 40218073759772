import { Task } from '../Task';
import { Component } from '@angular/core';

@Component({
    selector: "simple-task",
    templateUrl: "./simple-task.html"
})
export class SimpleTask {

    task: Task;

    constructor() {

    }

    async init(data: any){
        if(!!data.task){
            this.task = data.task;
        }
    }

}