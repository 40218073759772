import { Component, Input } from '@angular/core';
import { StatusChange } from '../StatusChange';

@Component({
  selector: '[xodos-status-change-item]',
  templateUrl: './status-change-item.component.html',
  styleUrls: ['./status-change-item.component.scss']
})
export class StatusChangeItemComponent {
  @Input() statusChange: StatusChange;
}
