<div class="page">

  <h2>Group</h2>

  <form *ngIf="object">
    <button class="btn btn-danger" id="deleteButton" (click)="delete(object)" [disabled]="!object.id">Delete</button>
    <div class="right-buttons">
      <button class="btn btn-default" id="cancelButton" (click)="cancel()">Cancel</button>
      <button class="btn btn-primary" id="saveButton" (click)="save(object)" type="submit">Save</button>
    </div>
    <xo-messages [fieldName]="null" [validationMessages]="validationMessages"></xo-messages>
    <br>
    <br>

    <xodos-group #component [exists]="exists" [group]="object" [validationMessages]="validationMessages" [validate]="validate"></xodos-group>

    <xo-messages [fieldName]="null" [validationMessages]="validationMessages"></xo-messages>
    <button class="btn btn-danger" id="deleteButton" (click)="delete(object)" [disabled]="!object.id">Delete</button>

    <div class="right-buttons">
      <button class="btn btn-default" id="cancelButton" (click)="cancel()">Cancel</button>
      <button class="btn btn-primary" id="saveButton" (click)="save(object)" type="submit">Save</button>
    </div>
  </form>
</div>