import { TabComponent } from '@xo/client-common';
import { Component, Input } from '@angular/core';
import { storypoints, durations, ESTIMATION_STORYPOINTS } from './Estimation';
import { ConfigService } from '../config/config.service';
import { Task } from '../task/Task';
import { Config } from '../config/Config';

@Component({
  selector: 'xodos-estimation',
  templateUrl: './estimation.component.html',
  styleUrls: ['./estimation.component.scss']
})
export class EstimationComponent {

  @Input()
  config: Config;
  @Input()
  task: Task;
  @Input()
  clickHandler: Function;
  configService: ConfigService;

  constructor(tab: TabComponent) {
    this.configService = tab.getService("Config") as ConfigService;
  }

  getEstimations(): string[] {
    if (this.config.estimationMethod === ESTIMATION_STORYPOINTS) {
      return storypoints;
    } else {
      return durations;
    }
  }
}
