import { Injectable, EventEmitter} from "@angular/core";
import { Group } from '../overview/group/component/Group';

export let feedGroupEmitter = new EventEmitter<Group>();

@Injectable()
export class FeedGroupContainerService {

    public feedGroup: Group;

    constructor(){
        this.subscribe()
    }

    subscribe(){
        feedGroupEmitter.subscribe((group)=>{
            this.feedGroup = group;
        });
    }
}