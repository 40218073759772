import { Component, Input } from "@angular/core";
import { Task } from '../../../task/Task';
import { CachingTaskService } from '../../../task/task.service';

@Component({
  selector: "xodos-mass-create",
  templateUrl: "./mass-create.component.html"
})
export class MassCreateComponent {


  @Input() task: Task;
  @Input() taskService: CachingTaskService;

  constructor() {
  }

  taskText: string = "";
  statusIndicator: boolean = false;
  childPrefix = "  ";

  createAll() {
    let payload = {
      taskText: this.taskText,
      childPrefix: this.childPrefix,
      statusIndicator: this.statusIndicator,
      taskCode: this.task.code
    }
    this.taskService.createAllTasks(payload).then(r => {
      console.log("r", r);
    })
  }


  childPrefixValues = {
    "tab": "\t",
    "1 space": " ",
    "2 space": "  ",
    "3 space": "   ",
    "4 space": "    "
  }
}
