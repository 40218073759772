import { Component, Input, OnInit } from "@angular/core";
import { TaskElementService } from '../../../task/task.service';
import { ToastrService } from 'ngx-toastr';
import { Task } from '../../../task/Task';
import { openTab, System } from '@xo/client-common';
import { closeTemplate } from './template.component';
import { TreeState } from '@xo/services';

@Component({
    selector: 'xodos-template-creator',
    templateUrl: './template-creator.component.html',
    styleUrls: ['./template-creator.component.scss']
})
export class TemplateCreatorComponent implements OnInit {

    constructor(
        private toastr: ToastrService) {
    }

    @Input() state: TreeState;
    @Input() taskService: TaskElementService;
    @Input() system: System;

    ngOnInit() {
        this.toBeTemplate = this.taskService.create();
        console.log("template", this.toBeTemplate);
        this.toBeTemplate.isTemplate = true;
    }

    clicked(task: Task) { }

    toBeTemplate: Task;

    createTemplateTask() {
        (this.toBeTemplate.XO_service as any).dataService.save(this.toBeTemplate, false).then(async (newTemplate) => {
            this.state.templates.push(newTemplate);
            this.toastr.info(`new Template created with brief: ${newTemplate.brief}`);
            closeTemplate.emit();
            openTab.emit({
                component: "Xodos/TaskOverviewComponent",
                label: newTemplate.brief,
                system: this.system,
                data: { newTemplate },
            });
        });
    }

    stopPropagation(event) {
        event.stopPropagation();
    }
}
