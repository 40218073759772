import { Component, Input } from '@angular/core';
import { Task } from '../../../../task/Task';


@Component({
  selector: 'xodos-status-change-list',
  templateUrl: './status-change-list.component.html',
  styleUrls: ['./status-change-list.component.scss']
})
export class StatusChangeListComponent {
  @Input() task: Task;
}
