import { GroupType } from './../../../../../application-e2e/src/testVariables';
import { Component } from "@angular/core";
import { SelectWindow, ModalService } from "@xo/client-common";
import { Group } from "./Group";

@Component({
    selector: "xodos-group-select",
    templateUrl: "./group.select.html",
    styleUrls: ["./group.select.scss"]
})
export class GroupSelect extends SelectWindow<Group> {

    constructor(modalService: ModalService) {
        super(modalService, GroupType, "name");
    }
}
