<div class="wrapper" *ngIf="!!state">
  <div>
    <form>
      <div>
        <label for="escalateOnHoldStatus">Escalate On Hold Status</label>
        <input type="checkbox" id="escalateOnHoldStatus" name="escalateOnHoldStatus" [(ngModel)]="state.config.escalateOnHoldStatus">
      </div>
      <div>
        <label for="escalateDoneStatus">Escalate Done Status</label>
        <input type="checkbox" id="escalateDoneStatus" name="escalateDoneStatus" [(ngModel)]="state.config.escalateDoneStatus">
      </div>
      <div>
        <label for="escalateReadyStatus">Escalate Ready Status</label>
        <input type="checkbox" id="escalateReadyStatus" name="escalateReadyStatus" [(ngModel)]="state.config.escalateReadyStatus">
      </div>
      <div>
        <label for="escalateClosedStatus">Escalate Closed Status</label>
        <input type="checkbox" id="escalateClosedStatus" name="escalateClosedStatus" [(ngModel)]="state.config.escalateClosedStatus">
      </div>

      <div>
        <label for="typeAppends">Type Appends</label>
        <input type="checkbox" id="typeAppends" name="typeAppends" [(ngModel)]="state.config.typeAppends">
      </div>

      <div>
        <label for="showWorklogOnStatusChange">Show Worklog on Status Change</label>
        <input type="checkbox" id="showWorklogOnStatusChange" name="showWorklogOnStatusChange" [(ngModel)]="state.config.worklogScreenOnStatusChange">
      </div>

      <div>
        <label for="estimationMethod">Estimation Method</label>
        <span *ngFor="let estimation of estimations">
          <input type="radio" id="estimationMethod" name="estimationMethod" value="{{estimation}}" [(ngModel)]="state.config.estimationMethod"> {{estimationLabels[estimation]}}
        </span>
      </div>

      <div>
        <label for="iconTypes">Choose a set of icons</label>
        <span *ngFor="let style of styles">
          <input type="radio" id="icon" name="icon" value="{{style}}" [(ngModel)]="state.config.iconTypes" />{{styleLabels[style]}}
        </span>
      </div>
    </form>
    <h2>Templates</h2>
    <xodos-template-config [system]="currentSystem" [state]="state"></xodos-template-config>
    <hr>
    <xodos-template-creator [system]="currentSystem" [taskService]="taskService" [state]="state"></xodos-template-creator>
  </div>
</div>
