import { Component, Input } from '@angular/core';
import { Task } from '../../../../task/Task';

@Component({
  selector: 'xodos-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent {

  @Input() task: Task;

}
