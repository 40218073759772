import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-xodos',
  templateUrl: './xodos.component.html',
  styles: []
})
export class XodosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
