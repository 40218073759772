<xo-tile class="simplecolumn2">
      <xo-field-string [object]="group" fieldName="name" [parent]="this"></xo-field-string>
      <xo-field-link [object]="group" fieldName="parent" [parent]="this" selectComponentName="Xodos/GroupSelect" linkComponentName="Xodos/GroupPage"></xo-field-link>
      <xo-field-string [object]="group" fieldName="childIndex" [parent]="this"></xo-field-string>
      <xo-field-string [object]="group" fieldName="code" [parent]="this"></xo-field-string>
      <xo-field-select [object]="group" fieldName="color" [parent]="this" [Type]="Group"></xo-field-select>
      <xo-field-string [object]="group" fieldName="weight" [parent]="this"></xo-field-string>
</xo-tile>

<xo-tile title="Groups" *ngIf="!!group && exists">
    <xodos-group-table #groupsWithParent
          [clickHandler]="createClickHandleForGroupParent()"
          [filters]="groupParentFilter"
          [allowCreate]="true">
    </xodos-group-table>
</xo-tile>