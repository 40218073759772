<table>
    <thead>
        <tr>
            <th>User</th>
            <th>Created</th>
            <th>Change</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let historyItem of listOfHistoryItems" [historyItem]="historyItem" xodos-history-item></tr>
    </tbody>
</table>
