import { Task } from './controller/Task';

export const STATUS_TODO = 'TODO';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_READY = 'READY';
export const STATUS_DONE = 'DONE';
export const STATUS_ON_HOLD = 'ON_HOLD';
export const STATUS_CLOSED = 'CLOSED';
export const STATUS_CANCELLED = 'CANCELLED';

export const SUBTASK_STATUS_TODO = 'SUBTASK_TODO';
export const SUBTASK_STATUS_IN_PROGRESS = 'SUBTASK_IN_PROGRESS';
export const SUBTASK_STATUS_READY = 'SUBTASK_READY';
export const SUBTASK_STATUS_ON_HOLD = 'SUBTASK_ON_HOLD';

export let Statuses = {};

export function convertSimpleToComplex(oldStatus: Status) {
    return SubTaskTaskStatusMap[oldStatus.id];
}

export function convertComplexToSimple(oldStatus: Status) {
    return TaskSubTaskStatusMap[oldStatus.id];
}

export class Status {
    id: string;
    label: string;
    class: string;

    constructor(id: string, brief: string) {
        this.id = id;
        this.label = brief;
        this.class = id;
    }

}

Statuses[STATUS_CANCELLED] = new Status(STATUS_CANCELLED, 'Cancelled');
Statuses[STATUS_ON_HOLD] = new Status(STATUS_ON_HOLD, 'On hold');
Statuses[STATUS_TODO] = new Status(STATUS_TODO, 'Todo');
Statuses[STATUS_IN_PROGRESS] = new Status(STATUS_IN_PROGRESS, 'In progress');
Statuses[STATUS_READY] = new Status(STATUS_READY, 'Ready');
Statuses[STATUS_DONE] = new Status(STATUS_DONE, 'Done');
Statuses[STATUS_CLOSED] = new Status(STATUS_CLOSED, 'Closed');

Statuses[SUBTASK_STATUS_ON_HOLD] = new Status(SUBTASK_STATUS_ON_HOLD, 'On hold');
Statuses[SUBTASK_STATUS_TODO] = new Status(SUBTASK_STATUS_TODO, 'Todo');
Statuses[SUBTASK_STATUS_IN_PROGRESS] = new Status(SUBTASK_STATUS_IN_PROGRESS, 'In progress');
Statuses[SUBTASK_STATUS_READY] = new Status(SUBTASK_STATUS_READY, 'Ready');

export let taskStatusOrder = [
    Statuses[STATUS_CANCELLED],
    Statuses[STATUS_ON_HOLD],
    Statuses[STATUS_TODO],
    Statuses[STATUS_IN_PROGRESS],
    Statuses[STATUS_READY],
    Statuses[STATUS_DONE],
    Statuses[STATUS_CLOSED]
];

export let subTaskStatusOrder = [
    Statuses[SUBTASK_STATUS_ON_HOLD],
    Statuses[SUBTASK_STATUS_TODO],
    Statuses[SUBTASK_STATUS_IN_PROGRESS],
    Statuses[SUBTASK_STATUS_READY]
];

export let TaskSubTaskStatusMap = {};

TaskSubTaskStatusMap[STATUS_ON_HOLD] = Statuses[SUBTASK_STATUS_ON_HOLD];
TaskSubTaskStatusMap[STATUS_TODO] = Statuses[SUBTASK_STATUS_TODO];
TaskSubTaskStatusMap[STATUS_IN_PROGRESS] = Statuses[SUBTASK_STATUS_IN_PROGRESS];
TaskSubTaskStatusMap[STATUS_READY] = Statuses[SUBTASK_STATUS_READY];
TaskSubTaskStatusMap[STATUS_DONE] = Statuses[SUBTASK_STATUS_READY];
TaskSubTaskStatusMap[STATUS_CLOSED] = Statuses[SUBTASK_STATUS_READY];
TaskSubTaskStatusMap[STATUS_CANCELLED] = Statuses[SUBTASK_STATUS_READY];

export let SubTaskTaskStatusMap = {};

SubTaskTaskStatusMap[SUBTASK_STATUS_ON_HOLD] = Statuses[STATUS_ON_HOLD];
SubTaskTaskStatusMap[SUBTASK_STATUS_TODO] = Statuses[STATUS_TODO];
SubTaskTaskStatusMap[SUBTASK_STATUS_IN_PROGRESS] = Statuses[STATUS_IN_PROGRESS];
SubTaskTaskStatusMap[SUBTASK_STATUS_READY] = Statuses[STATUS_READY];

export const LOW = 'LOW';
export const NORMAL = 'NORMAL';
export const HIGH = 'HIGH';

export const PROJECT = 'PROJECT';
export const COMPONENT = 'COMPONENT'
export const TASK = 'TASK';
export const FEATURE = 'FEATURE'
export const SUBTASK = 'SUBTASK';
export const BUG = 'BUG';

export function getNewStatus(oldType, newType, oldStatus) {
    if ((simpleTypes.indexOf(oldType) >= 0) && (simpleTypes.indexOf(newType) >= 0)) return oldStatus;
    if ((complexTypes.indexOf(oldType) >= 0) && (complexTypes.indexOf(newType) >= 0)) return oldStatus;

    if ((simpleTypes.indexOf(oldType) >= 0) && (complexTypes.indexOf(newType) >= 0)) return convertSimpleToComplex(oldStatus);
    if ((complexTypes.indexOf(oldType) >= 0) && (simpleTypes.indexOf(newType) >= 0)) return convertComplexToSimple(oldStatus);
}

export let simpleTypes = [
    COMPONENT,
    FEATURE,
    SUBTASK
];

export let complexTypes = [
    PROJECT,
    TASK,
    BUG
]

export let Types = [
    'PROJECT',
    'COMPONENT',
    'FEATURE',
    'TASK',
    'SUBTASK',
    'BUG'
];

export let TypeLabels = {}
TypeLabels[PROJECT] = "Project";
TypeLabels[COMPONENT] = "Component";
TypeLabels[FEATURE] = "Feature";
TypeLabels[TASK] = "Task";
TypeLabels[SUBTASK] = "Subtask";
TypeLabels[BUG] = "Bug";

export class Type {

    constructor(public code?: string, public iconClass?: string) {
        if (code) {
            if ([PROJECT, TASK, BUG].indexOf(code) >= 0) {
                //TODO subclassing
                this.statusGroup = taskStatusOrder;
            } else {
                //TODO subclassing
                this.statusGroup = subTaskStatusOrder;
            }
        }
    }

    statusGroup: Status[];

    nextStatus(status: Status) {
        if (status.id === STATUS_CLOSED || status.id === STATUS_CANCELLED || status.id === SUBTASK_STATUS_READY) {
            return status;
        } else {
            return this.statusGroup[this.statusGroup.indexOf(status) + 1];
        }
    }

    prevStatus(status: Status) {
        if (status.id === STATUS_ON_HOLD || status.id === STATUS_CANCELLED || status.id === SUBTASK_STATUS_ON_HOLD) {
            return status;
        } else {
            return this.statusGroup[this.statusGroup.indexOf(status) - 1];
        }
    }
}

export let types = {
    PROJECT: new Type(PROJECT, PROJECT),
    COMPONENT: new Type(COMPONENT, COMPONENT),
    FEATURE: new Type(FEATURE, FEATURE),
    TASK: new Type(TASK, TASK),
    SUBTASK: new Type(SUBTASK, SUBTASK),
    BUG: new Type(BUG, BUG)
}

export let typeArray = Object.keys(types).map(key => types[key]);



export function printTree(task: Task, prefix: string = '') {
    console.log(`${prefix} ${(task.active ? '*' : ' ')} ${task.brief} (${task.status.id})`);
    if (task.active) {
        console.log(`${prefix} ${task.type} ${task.priority} ${task.assignee && task.assignee.name} ${task.deadline}`);
        console.log(`${prefix} ${task.description} ${task.estimation} ${task.context}`);
        task.worklogs.forEach((worklog) => {
            console.log(`${prefix} WORKLOGS:`, worklog.comment, worklog.user, worklog.created, worklog.duration);
        });
        task.statusChanges.forEach((changes) => {
            console.log(`${prefix} STATUSES:`, changes.user, changes.created, changes.oldStatus.id, changes.newStatus.id);
        });
        task.comments.forEach((comment) => {
            console.log(`${prefix} COMMENTS:`, comment.user, comment.created, comment.comment);
        });
        (task as any).history.forEach((entry: any) => {
            console.log(`${prefix} History:`, entry.user, entry.timestamp, entry.description);
        });
    }
    task.subtasks.forEach((subTask) => {
        this.printTree(subTask, prefix + ' -');
    });
}

const colorValues = new Map<string, string>();
colorValues.set("green", "Green");
colorValues.set("blue", "Blue");
colorValues.set("smoke", "Smoke");

export let GroupType = "Group";

export class Group {

    get label() {
        return this.name;
    }

    set label(label) {
        this.name = label;
    }

    public static colorKeys: string[] = Array.from(colorValues.keys());
    public static getColorValues(key: string): string {
        return colorValues.get(key);
    }

    public name: string;
    public id: number;
    public parent: any;
    public groups: Group[] = new Array<Group>();
    public weight: number = 1;
    public color: string = '';
    public code: string = '';
    childIndex: number;

    refreshChildrenIndex() {
        for (let i = 0; i < this.groups.length; i++) {
            this.groups[i].childIndex = i;
            if (this.groups[i].groups.length > 0) {
                this.refreshChildrenIndex.call(this.groups[i]);
            }
        }
    }

    public addGroup(group: Group, index?: number): void {
        if (index && !this.getSubGroupById(group.id)) {
            this.groups.splice(index, 0, group);
            group.parent.id = this.id;
        } else {
            if (!this.getSubGroupById(group.id)) {
                this.groups.push(group);
                group.parent.id = this.id;
            }
        }
        this.refreshChildrenIndex();
    }

    public getSubGroups(): Group[] {
        return this.groups;
    }

    getSubGroupById(id: number): Group {
        for (let i = 0; i < this.groups.length; i++) {
            let currentGroup = this.groups[i];
            if (currentGroup.id == id) {
                return currentGroup;
            }
        }
        return null;
    }
    removeSubGroup(group: Group): void {
        let index = this.groups.indexOf(group, 0);
        if (index >= 0) {
            this.groups.splice(index, 1);
        }
        this.refreshChildrenIndex();
    }
}

export class Worklog {
    id: number;
    taskCode: string;
    user = null;
    created: Date;
    duration: string;
    comment: string;
    statusChangeId?: number;

    constructor(taskCode?: string) {
        if (taskCode) {
            this.taskCode = taskCode;
        }
    }
}

export class Comment {
    id: number;
    user: string;
    created: Date;
    comment: string;
}

export class StatusChange {
    id: number;
    user: string;
    created: Date;
    oldStatus: Status;
    newStatus: Status;
}
