import { Node, encodeHTML } from "@xo/services";
import { Status, Statuses, STATUS_TODO } from '../common/model/Status';
import { types, TASK, Type, getNewStatus } from '../common/model/Type';
import { Worklog } from '../worklog/Worklog';
import { StatusChange } from '../overview/task/status-change/StatusChange';
import { Comment } from "../overview/task/comment/Comment";
import { User } from '../common/model/User';
import { CONTEXT_WORK } from '../common/model/Context';
import { Group } from '../overview/group/component/Group';

export const typeValues = new Map<string, string>();
typeValues.set("PROJECT", "Project");
typeValues.set("TASK", "Task");
typeValues.set("SUBTASK", "Sub Task");

export const statusValues = new Map<string, string>();
statusValues.set("CANCELLED", "Cancelled");
statusValues.set("ON_HOLD", "On Hold");
statusValues.set("TODO", "TODO");
statusValues.set("IN_PROGRESS", "In Progress");
statusValues.set("READY", "Ready");
statusValues.set("DONE", "Done");
statusValues.set("CLOSED", "Closed");
statusValues.set("SUBTASK_TODO", "ToDo");
statusValues.set("SUBTASK_IN_PROGRESS", "InProgress");
statusValues.set("SUBTASK_READY", "DONE");

export const contextValues = new Map<string, string>();
contextValues.set("WORK", "Work");
contextValues.set("HOME", "Home");
contextValues.set("TELEPHONE", "Telephone");
contextValues.set("PROJECT", "Project");

export const priorityValues = new Map<string, string>();
priorityValues.set("LOW", "Low");
priorityValues.set("NORMAL", "Normal");
priorityValues.set("HIGH", "High");


export let TaskType = "Task";

export class Task extends Node {

    constructor() {
        super(TaskType, "Xodos/SimpleTask");
    }


    public static typeKeys: string[] = Array.from(typeValues.keys());
    public static getTypeValues(key: string): string {
        return typeValues.get(key);
    }

    public static statusKeys: string[] = Array.from(statusValues.keys());
    public static getStatusValues(key: string): string {
        return statusValues.get(key);
    }

    public static contextKeys: string[] = Array.from(contextValues.keys());
    public static getContextValues(key: string): string {
        return contextValues.get(key);
    }

    public static priorityKeys: string[] = Array.from(priorityValues.keys());
    public static getPriorityValues(key: string): string {
        return priorityValues.get(key);
    }

    code: string;
    parentCode: string = null;
    parentLabel: string = null;
    childIndex: number;
    user: User = null;
    brief: string;
    status: Status = Statuses[STATUS_TODO];
    description: string = '';
    context: string = CONTEXT_WORK;
    assignee: Group;
    estimation: string = null;
    _type: Type = types[TASK];
    deadline: Date;
    priority: string;

    subtasks: Task[] = new Array<Task>();
    worklogs: Worklog[] = new Array<Worklog>();
    statusChanges: StatusChange[] = [];
    comments: Comment[] = [];

    activeChildIndex: number = 0;

    showSubTasks: boolean = false; //TODO remove, XO_ShowChildren
    matched: boolean = false;
    hidden: boolean = false;
    moving: boolean = false;
    isTemplate: boolean = false;

    created: Date;

    equals(task: Task) {
        return task.code === this.code;
    }


    get id() {
        return this.code;
    }

    set id(id) {
        this.code = id;
    }
    get label() {
        return this.brief;
    }

    set label(label: string) {
        this.brief = label;
    }

    set type(newType: Type) {
        if (!this.status) {
            throw new Error("Please set the status first!");
        }
        let previousType = this._type;
        this.status = getNewStatus(previousType.code, newType.code, this.status);
        this._type = newType;
    }

    get type() {
        return this._type;
    }

    get classes() {
        return this.assignee?.color;
    }

    set classes(c) { }

    getWorklogs(): Worklog[] {
        return this.worklogs;
    }

    addWorklog(worklog: Worklog) {
        worklog.taskCode = this.code;
        this.worklogs.push(worklog);
    }

    refreshChildrenIndex() {
        for (let i = 0; i < this.subtasks.length; i++) {
            this.subtasks[i].childIndex = i;
        }
    }

    addSubTask(task: Task, index?: number) {
        if (this.subtasks.indexOf(task) >= 0) {
            return;
        }
        if (index || index === 0) {
            this.subtasks.splice(index, 0, task);
        } else {
            this.subtasks.push(task);
        }
        task.parentCode = this.code;
        this.refreshChildrenIndex();
    }


    getSubTasks() {
        return this.subtasks;
    }

    removeSubTask(task) {
        let taskIndex = this.subtasks.indexOf(task);
        console.log("subtaskok", this.subtasks, "\nén", task, "\nindex", taskIndex);
        if (taskIndex < 0) {
            return;
        }
        this.subtasks.splice(taskIndex, 1);
        return this;
    }


    getNodeSvg() {
        /*       `<rect width="${this.svgNode.width + 20}" height="${this.svgNode.height}" fill="green"/>`
             */

        /*
        //TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//

        `<polygon points="250,60 100,400 400,400" class="triangle" />`

        `<polygon points="100,10 40,198 190,78 10,78 160,198" style="fill:lime;stroke:purple;stroke-width:5;fill-rule:evenodd;" />`

        `<polygon points="100,10 40,198 190,78 10,78 160,198" style="fill:lime;stroke:purple;stroke-width:5;fill-rule:evenodd;" />`


        //TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//TRIALS//
        `<rect width="${this.svgNode.width}" height="${this.svgNode.height + 25}" fill="gold"/>`
        */



        return `<polygon points="15,0 0,34 30,34" style="fill:lime;stroke:purple;stroke-width:5;" />`
            + `<text alignment-baseline="central" x="10" y="${this.svgNode.height / 2}">${encodeHTML(this.label)}</text>`;
    }

}

