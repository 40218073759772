import { UpdaterService } from "@xo/client-common";
import { TreeState, CachingDataService } from "@xo/services";
import { Task } from './Task';
import { CachingTaskService } from './task.service';
import { NgZone } from '@angular/core';

export class TaskUpdaterService extends UpdaterService<Task> {

    constructor(private treeState: TreeState, dataService: CachingDataService<Task>, interval: any, ngZone: NgZone) {
        super(dataService, interval, ngZone);
    }

    async refresh() {
        let changedTasks = await super.refresh();
        this.resetParents(changedTasks);
        return changedTasks;
    }

    resetParents(changedTasks: Task[]) {
        changedTasks.forEach(task => {
            let parent = (this.dataService as CachingTaskService).get(task.parentCode);
            if (!parent) {
                return;
            }
            if (parent.subtasks.includes(task)) {
                if (task["XO_DELETED"]) {
                    parent.removeSubTask(task);
                    if (this.treeState.activeElement.id == task.id) {
                        this.treeState.activeElement = parent;
                    }
                }
                parent.subtasks.sort(sortByChildIndex)
                return;
            }
            this.removeFromParent(task);
            this.addToParent(parent, task);
        });
    }

    removeFromParent(task: Task) {
        (this.dataService as CachingTaskService).objects.forEach(oldParent => {
            if (oldParent.subtasks.includes(task)) {
                oldParent.removeSubTask(task);
                oldParent.subtasks.sort(sortByChildIndex);
            }
        })
    }

    addToParent(parent: Task, task) {
        if (task["XO_DELETED"]) {
            return;
        }
        parent.addSubTask(task, task.childIndex);
        parent.subtasks.sort(sortByChildIndex)
    }
}

function sortByChildIndex(task1: Task, task2: Task) {
    if (task1.childIndex > task2.childIndex) {
        return 1;
    }
    if (task1.childIndex < task1.childIndex) {
        return -1;
    }
    return 0;
}
