<div (click)="setGroup($event)" [ngClass]="getClasses()">
    <div title="{{group.name}}">{{group.code}}</div>
    <div class="subgroups">
        <div *ngIf="groupType == 'setGroup'">
            <xodos-set-group *ngFor="let subGroup of group.getSubGroups()" [group]="subGroup"
                [ngStyle]="{'max-width.%': subGroup.weight/sumWeight*100}" [task]="task" [groupService]="groupService">
            </xodos-set-group>
        </div>
        <div *ngIf="groupType == 'filterGroup'">
            <xodos-filter-group [state]="state" [filterFunction]="filterFunction"
                *ngFor="let subGroup of group.getSubGroups()" [group]="subGroup"
                [ngStyle]="{'max-width.%': subGroup.weight/sumWeight*100}" [groupService]="groupService">
            </xodos-filter-group>
        </div>
        <div *ngIf="groupType == 'feedGroup'">
            <app-feed-group *ngFor="let subGroup of group.getSubGroups()" [group]="subGroup"
                [ngStyle]="{'max-width.%': subGroup.weight/sumWeight*100}" [groupService]="groupService">
            </app-feed-group>
        </div>
    </div>
</div>
