import { GroupType } from './../../../../../application-e2e/src/testVariables';
import { Group } from "./Group";
import { Component, OnInit, Optional } from "@angular/core";
import { PagingTableComponent, ModalTabComponent, TabComponent } from "@xo/client-common";
import { CachingGroupService } from "./group.service";

@Component({
    selector: "xodos-group-table",
    styleUrls: ["./group.table.scss"],
    templateUrl: "./group.table.html"
})
export class GroupTable extends PagingTableComponent<Group> implements OnInit {

    Group = Group;

    groupsForParent = [];

    colorSelectValues = [];

    groupDataService: CachingGroupService;
    dataService: CachingGroupService;

    constructor(@Optional() tab: TabComponent, @Optional() modalTab: ModalTabComponent) {
        super(GroupType, Group.columnInfo, tab || modalTab);
        this.colorSelectValues = this.createReferenceMap(Group.colorKeys, Group.getColorValues);
    }

    async ngOnInit() {
        await this.loadTargetsForParent();
        await this.refreshList();
        console.log(this.objects)
    }

    async loadTargetsForParent() {
        this.groupsForParent = (await this.dataService.list({}, "loadTargetsForParent" + this.id)).page;
    }

    getClasses(group) {
        let classes: any = { "objects": true };
        return classes;
    }
}
