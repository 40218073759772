import { HistoryItem } from "../common/model/HistoryItem";

export class Worklog implements HistoryItem {
    id: number;
    taskCode: string;
    user = null;
    created: Date;
    duration: string;
    comment: string;
    statusChangeId?: number;

    constructor(taskCode?: string) {
        if (taskCode) {
            this.taskCode = taskCode;
        }
    }
}
