<div align="center">
    <button class="btn" (click)="updateTasks()">GET FEED</button>
    <table id="settings">
        <thead>
            <th>
                RangeSelector
            </th>
            <th>
                StatusSelector
            </th>
            <th>
                AssigneeSelector
            </th>
        </thead>
        <tr>
            <td>
                <select [(ngModel)]="range" style="width: 100%" (change)="updateTasks()" id="rangeSelector">
                    <option *ngFor="let range of ranges | keyvalue" value="{{range.key}}">
                        {{range.key}}
                    </option>
                </select>
            </td>
            <td>
                <select [(ngModel)]="status" style="width: 100%" (change)="updateTasks()" id="statusSelector">
                    <option *ngFor="let status of statuses" value="{{status}}">
                        {{Statuses[status].label}}
                    </option>
                </select>
            </td>
            <td>
                <app-feed-group [groupService]="groupService"></app-feed-group>
            </td>
        </tr>
    </table>
    <br />
    <table id="feed">
        <thead>
            <th>
                Timestamp
            </th>
            <th>
                Brief
            </th>
            <th>
                Description
            </th>
            <th>
                Current Status
            </th>
            <th>
                Current Assignee
            </th>
        </thead>
        <tr *ngFor="let task of tasks" (click)="feedItemClicked(task)">
            <td class="feedTimestamp">
                {{task.timestamp | date: 'yyyy-MM-dd hh:mm'}}
            </td>
            <td class="feedBrief">
                {{task.brief}}
            </td>
            <td class="feedDescription">
                {{task.description}}
            </td>
            <td class="feedStatus">
                {{Statuses[task.status].label}}
            </td>
            <td class="feedAssignee">
                {{task.assignee?.label}}
            </td>
        </tr>
    </table>
</div>