<div>
  <div>
    <span id="taskBrief">{{task.brief}}</span>
    <br> Duration:
    <input type="text" id="duration" [(ngModel)]="duration" autofocus (keypress)="stopPropagation($event)" (keyup)="stopPropagation($event)" (keydown)="stopPropagation($event)"/>
    <br> Comment:
    <textarea [(ngModel)]="comment" id="comment" (keypress)="stopPropagation($event)" (keyup)="stopPropagation($event)" (keydown)="stopPropagation($event)"></textarea>
    <br>
    <button (click)="saveNewWorklog()" id="saveWorklogButton">Save</button>
    <button (click)="cancel()"  id="cancelWorklogButton">Cancel</button>
  </div>
</div>