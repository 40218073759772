import { StatusChange } from "./StatusChange";
import { errorHandler } from "@xo/client-common";
import { HttpClient } from "@angular/common/http";
import { StatusChangeUrlService } from "./status-change.url.service";
import { Task } from "../../../task/Task";
import { Transformator, DataService } from '@xo/services';

export class StatusChangeTransformator extends Transformator<StatusChange> {

  async transformFromServer(rawStatusChange: any, localStatusChange?: StatusChange): Promise<StatusChange> {
    let result: StatusChange = localStatusChange ? localStatusChange : new StatusChange();
    result = Object.assign(result, rawStatusChange);
    return result;
  }

  transformToServer(statusChange: StatusChange): any {
    let result: any = Object.assign({}, statusChange);
    return result;
  }
}

export class StatusChangeService extends DataService<StatusChange> {

  constructor(http: HttpClient, protected statusChangeUrlService: StatusChangeUrlService, statusChangeTransformator: StatusChangeTransformator) {
    super(http, statusChangeUrlService, statusChangeTransformator, "id");
  }

  getTaskStatusChangeHttp(task: Task) {
    return this.http.get(this.statusChangeUrlService.getTypedTaskStatusUrl(task), { withCredentials: true })
      .toPromise()
      .catch(errorHandler);
  }

}
