<div class="container">
    <table>
        <thead>
            <tr>
                <th>User</th>
                <th>TimeStamp</th>
                <th>Change</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let statusChange of task.statusChanges" xodos-status-change-item [statusChange]="statusChange">
            </tr>
        </tbody>
    </table>
</div>
