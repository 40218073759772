<div class="container">
    <table>
        <thead>
            <tr>
                <th>Username</th>
                <th>Timestamp</th>
                <th>Comment</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let comment of task.comments" xodos-comment-item [comment]="comment"></tr>
        </tbody>
    </table>
</div>
