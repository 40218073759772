import { Component, Input } from '@angular/core';
import { AbstractGroupComponent, FILTER } from '../group/group.component';
import { Group } from '../group/component/Group';
import { TreeState } from '@xo/services';

@Component({
  selector: 'xodos-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['../group/group.component.scss']
})
export class FilterGroupComponent extends AbstractGroupComponent {

  constructor() {
    super();
    this.groupType = FILTER;
  }

  @Input() filterFunction: any;
  @Input() state: TreeState;

  task;

  clicked(group: Group, event: Event) {
    if (group === this.state.filters.assignee) {
      this.state.filters.assignee = null;
      this.filterFunction();
    } else {
      this.state.filters.assignee = group;
      this.filterFunction();
    }
    event.stopPropagation();
  }

  isSelected() {
    return this.isGroupOrAnchestorsSelected(this.group);
  }

  isGroupOrAnchestorsSelected(group: Group): boolean {
    if (!this.state.filters.assignee) {
      return false;
    }
    if (!group) {
      return false;
    }

    if (group.id === this.state.filters.assignee.id) {
      return true;
    }

    if (!group.parent) {
      return false;
    }

    let parentGroup = this.groupService.getGroup(group.parent.id);
    return this.isGroupOrAnchestorsSelected(parentGroup);
  }
}
