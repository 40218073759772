import { Component, ComponentRef } from '@angular/core';
import { Estimations, EstimationLabels } from '../estimation/Estimation';
import { Styles, StyleLabels } from '../common/model/Style';
import { ModalWindow, ModalService, System } from '@xo/client-common';
import { closeTemplate } from '../overview/task/template/template.component';
import { TaskElementService } from '../task/task.service';
import { TreeState } from '@xo/services';
import { IModalDialog, IModalDialogOptions } from '@preeco-privacy/ngx-modal-dialog';

@Component({
  selector: 'xodos-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent extends ModalWindow {

  state: TreeState

  estimations: string[];
  estimationLabels: Object;
  styles: string[];
  styleLabels: Object;
  differ: any;
  currentSystem: System;

  taskService: TaskElementService;

  constructor(modalService: ModalService) {
    super(modalService);
    this.estimationLabels = EstimationLabels;
    this.estimations = Estimations;
    this.styles = Styles;
    this.styleLabels = StyleLabels;
    closeTemplate.subscribe(() => {
      this.closeConfig();
    });
  }

  async dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    options.data.disableCancel = true;
    super.dialogInit(reference, options);
    this.state = this.options.data.state;
    this.taskService = this.options.data.taskService;
    this.currentSystem = this.options.data.system;
  }

  closeConfig() {
    this.options.closeDialogSubject.next();
  }
}
