import { EventEmitter } from '@angular/core';
import { Config } from './Config';
import { ConfigChange } from '../overview/services/changes.service';
import { errorHandler } from '@xo/client-common';
import { HttpClient } from '@angular/common/http';
import { ConfigUrlService } from "./config.url.service";
import { Transformator, DataService } from '@xo/services';

export class ConfigTransformator extends Transformator<Config> {

    async transformFromServer(rawConfig: any, localConfig?: Config): Promise<Config> {
        let result: Config = localConfig ? localConfig : new Config();
        result = Object.assign(result, rawConfig);
        return result;
    }

    transformToServer(group: Config): any {
        let result: any = Object.assign({}, group);
        return result;
    }

}

export class ConfigService extends DataService<Config> {

    constructor(public http: HttpClient, protected configUrlService: ConfigUrlService, protected configTransformator: ConfigTransformator) {
        super(http, configUrlService, configTransformator, "id");
    }


    public static configChanged: EventEmitter<ConfigChange> = new EventEmitter<ConfigChange>();

    config: Config = new Config();

    getConfigHttp() {
        return this.http.get(this.configUrlService.getConfigUrl(), { withCredentials: true })
            .toPromise()
            .catch(errorHandler);
    }

    putConfigHttp(update: Object) {
        return this.http.put(this.configUrlService.getConfigUrl(), update, { withCredentials: true })
            .toPromise()
            .catch(errorHandler);
    }

}
