import { IModalDialog, IModalDialogOptions } from '@preeco-privacy/ngx-modal-dialog';
import { Component, ComponentRef } from "@angular/core";
import { ModalWindow, ModalService } from '@xo/client-common';

@Component({
    selector: "xodos-legend",
    styleUrls: [],
    templateUrl: "./legend.component.html"
})
export class LegendComponent extends ModalWindow {

    legendMap: Map<string, string>;
    legendKeys;
    getLegendValue;



    async dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
        this.options = options;
    }

    constructor(modalService: ModalService) {
        super(modalService);
        this.initLegends();
    }

    returnOutPut(key) {
        return `${key} - ${this.getLegendValue(key)}`;
    }

    initLegends() {
        this.legendMap = new Map<string, string>();
        this.legendMap.set("+", "Következő státusz");
        this.legendMap.set("-", "Előző státusz");
        this.legendMap.set("?", "ON_HOLD státusz");
        this.legendMap.set("&#x2192;", "Közvetlen gyerekhez");
        this.legendMap.set("&#x2190;", "Közvetlen szülőhöz");
        this.legendMap.set("&#x2191;", "Felfelé mozgás testvérek között");
        this.legendMap.set("&#x2193;", "Lefelé mozgás testvérek között");

        this.legendKeys = Array.from(this.legendMap.keys());
        this.getLegendValue = (key) => { return this.legendMap.get(key) };
    }

}
