import { Component, Input } from '@angular/core';

@Component({
  selector: 'xodos-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss']
})
export class HistoryListComponent{
  @Input() listOfHistoryItems: any[];
}
