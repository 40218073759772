import { IModalDialog, IModalDialogOptions } from '@preeco-privacy/ngx-modal-dialog';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Contexts, ContextLabels } from '../../../common/model/Context';
import { typeArray, PROJECT } from '../../../common/model/Type';
import { Statuses } from '../../../common/model/Status';
import { Comment } from '../comment/Comment';
import { Priorities } from '../../../common/model/Priority';
import { modalEmitter, openTab, TabComponent } from '@xo/client-common';
import { Config } from '../../../config/Config';
import { Task } from '../../../task/Task';
import { WorklogService } from '../../../worklog/worklog.service';
import { CommentService } from '../comment/comment.service';
import { StatusChangeService } from '../status-change/status-change.service';
import { StatusChange } from '../status-change/StatusChange';
import { CachingTaskService } from '../../../task/task.service';
import { TreeState } from '@xo/services';


@Component({
  selector: 'xodos-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})

export class TaskDetailsComponent implements OnChanges, IModalDialog {
  @Input()
  state: TreeState;

  @Input()
  task: Task;

  @Input()
  config: Config;

  @Input() taskService: CachingTaskService;
  @Input() system;

  showWorklogs: boolean = false;
  showStatusTab: boolean = false;
  setEstimation: Function;
  Contexts: any;
  ContextLabels: any;
  types: any;
  Priorities: any;
  showTaskComment: boolean = false;
  comment: string = null;
  showHistory: boolean = false;

  PROJECT = PROJECT;

  closeModal;

  statusChangeService: StatusChangeService;
  worklogService: WorklogService;
  commentService: CommentService;

  constructor(private tab: TabComponent) {
    this.setEstimation = (value: string) => {
      this.task.estimation = value;
    };
    this.Contexts = Contexts;
    this.ContextLabels = ContextLabels;
    this.types = typeArray;
    this.Priorities = Priorities;

    this.statusChangeService = this.tab.getService("StatusChange") as StatusChangeService;
    this.worklogService = this.tab.getService("Worklog") as WorklogService;
    this.commentService = this.tab.getService("Comment") as CommentService;
  }

  dialogInit(reference: import("@angular/core").ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    this.state = options.data.data.state;
    this.task = options.data.data.task;
    this.config = options.data.data.config;
    this.system = options.data.data.system;
    this.taskService = options.data.data.taskService;
    this.closeModal = () => options.closeDialogSubject.next();
  }

  openWorklog(task) {
    modalEmitter.emit({
      component: "Xodos/WorklogComponent",
      data: { task: task, worklogService: this.worklogService }
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  ngOnChanges(changes: SimpleChanges) {
    let change = changes.task;
    if (change.previousValue != change.currentValue) {
      this.loadHistoryItems(this.task);
    }
  }

  listOfHistoryItems: any[] = [];

  async loadHistoryItems(task: Task) {
    await this.loadComments(task);
    await this.loadStatusChanges(task);
    await this.loadWorklogs(task.code);
    this.refreshHistory();
  }

  refreshHistory() {
    this.listOfHistoryItems = [];
    this.task.comments.forEach((comment) => {
      this.listOfHistoryItems.push(comment);
    });
    this.task.statusChanges.forEach((statusChange) => {
      this.listOfHistoryItems.push(statusChange);
    });
    this.task.worklogs.forEach((worklog) => {
      this.listOfHistoryItems.push(worklog);
    });
    this.listOfHistoryItems = this.listOfHistoryItems.sort(this.compareTimestamps);
  }

  async setNextStatus() {
    await (this.task.XO_service as any).setNextStatus(this.state);
    await this.loadStatusChanges(this.task);
    this.refreshHistory();
  }

  async setPrevStatus() {
    await (this.task.XO_service as any).setPreviousStatus(this.state);
    await this.loadStatusChanges(this.task);
    this.refreshHistory();
  }

  nextStatus() {
    return this.task.type.nextStatus(this.task.status);
  }

  prevStatus() {
    return this.task.type.prevStatus(this.task.status);
  }


  openTaskInNewTab(task: Task) {
    console.log("openTaskInNewTab", this.system, task.code);
    if (task.subtasks.length == 0) {
      console.log("no children not opening");
      return;
    }
    openTab.emit({
      component: "Xodos/TaskOverviewComponent",
      data: { taskCode: task.code, system: this.system },
      label: task.brief,
      system: this.system
    });
  }

  compareTimestamps(a, b) {
    if (a["created"] === b["created"]) {
      return 0;
    }
    return (a["created"] > b["created"]) ? -1 : 1;
  }

  addComment(task: Task) {
    let payLoad = {
      task: { code: task.code },
      comment: this.comment
    }
    this.commentService.postTaskComment(task, payLoad)
      .then((response: any) => {
        let serverComment = response;
        let comment = new Comment();
        this.setCommentFields(comment, serverComment)
        task.comments.push(comment);
      });
    this.comment = null;
  };

  async loadComments(task: Task) {
    task.comments = [];
    return this.commentService.getTaskComments(task)
      .then((response: any) => {
        let newComments = response;
        this.task.comments = [];
        console.log("comments", newComments)
        newComments.forEach((newComment) => {
          let comment = new Comment();
          this.setCommentFields(comment, newComment);
          task.comments.push(comment);
        });
      });
  }

  async loadStatusChanges(task: Task) {
    return this.statusChangeService.getTaskStatusChangeHttp(task)
      .then((response: any) => {
        let newStatusChanges = response;
        this.task.statusChanges = [];
        newStatusChanges.forEach((newStatusChange) => {
          let statusChange = new StatusChange();
          statusChange.user = newStatusChange.user.label;
          statusChange.id = newStatusChange.id;
          statusChange.created = new Date(newStatusChange.timestamp);
          statusChange.oldStatus = Statuses[newStatusChange.oldStatus];
          statusChange.newStatus = Statuses[newStatusChange.newStatus];
          this.task.statusChanges.push(statusChange);
        });
      });
  }

  async loadWorklogs(id) {
    return this.worklogService.getWorklogHttp(id, '?task=')
      .then((response: any) => {
        let worklogs = response;
        this.task.worklogs = [];
        worklogs.forEach((worklog: any) => {
          this.task.worklogs.push(worklog);
        });
      });
  }

  setCommentFields(comment, serverComment) {
    comment.id = serverComment.id;
    comment.user = serverComment.user.label;
    comment.created = new Date(serverComment.timestamp);
    comment.comment = serverComment.comment;
  }

  changeComment(event) {
    this.comment = event.target.value;
    event.stopPropagation();
  }

  changeShowWorklogs(): void {
    this.showWorklogs = !this.showWorklogs;
  }

  changeShowStatusTab(): void {
    this.showStatusTab = !this.showStatusTab;
  }

  changeShowTaskComment(): void {
    this.showTaskComment = !this.showTaskComment;
  }

  changeShowHistory(): void {
    this.showHistory = !this.showHistory;
  }

  setTaskContext(event: any) {
    this.task.context = event.target.value;
    event.stopPropagation();
  }

  setDescription(event: any) {
    this.task.description = event.target.value;
    event.stopPropagation();
  }

  setTaskType(event: any) {
    this.task.type = event.target.value;
    event.stopPropagation();
  }

  setTaskPriority(event: any) {
    this.task.priority = event.target.value;
    event.stopPropagation();
  }

  determineSetter(event) {
    let tabTitle = event.tabTitle;
    switch (tabTitle) {
      case 'Worklogs':
        this.loadWorklogs(this.task.code);
        break;
      case 'Status':
        this.loadStatusChanges(this.task);
        break;
      case 'Comments':
        this.loadComments(this.task);
        break;
      case 'General':
        this.loadHistoryItems(this.task);
        break;
    }
  }

  export(type: string) {
    this.taskService.export(this.task.code, type);
  }
}

