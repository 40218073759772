<div *ngIf="displayFilterComp() && !!state.filters && !!groupService" class="filters">
    <div class="summary">
        {{state.filters.stat.matched}} / {{state.filters.stat.total}}
        <ng-container *ngIf="state.filters.showOnlyMatchedTasks">({{state.filters.stat.visible}} visible)
        </ng-container>
    </div>
    <div>
        <label for="showOnlyMatchedTasks">Hide unmatched</label>
        <input type="checkbox" name="showOnlyMatchedTasks" [(ngModel)]="state.filters.showOnlyMatchedTasks"
            (click)="setShowOnlyMatchedTasks($event)">
    </div>

    <div>
        <label for="briefFilter">Search By Brief</label>
        <br>
        <input type="text" id="briefFilter" name="briefFilter" (keyup)="setBriefFilter($event)"
            (keydown)="cancelBubble($event)" [(ngModel)]="state.filters.brief">
    </div>

    <div>
        <label for="descriptionFilter">Search By Description</label>
        <br>
        <input type="text" id="descriptionFilter" name="descriptionFilter" (keyup)="setDescriptionFilter($event)"
            (keydown)="cancelBubble($event)" [(ngModel)]="state.filters.description">
    </div>
    <div>
        <label for="status">Search By Status</label>
        <br>
        <select id="statusSelect" (change)="setStatusFilter($event)" multiple [(ngModel)]="state.filters.status">
            <option *ngFor="let Status of statusOrder" value="{{Status.id}}">{{Status.label}}</option>
        </select>
    </div>
    <div>
        <label for="hiddenStatus">Hide Tasks with Statuses</label>
        <br>
        <select id="hiddenStatusSelect" (change)="setExcludedStatusFilter($event)" multiple
            [(ngModel)]="state.filters.excludeStatus">
            <option *ngFor="let Status of statusOrder" value="{{Status.id}}">{{Status.label}}</option>
        </select>
    </div>
    <div>
        <label for="context">Search by Context</label>
        <br>
        <select id="contextSelect" (change)="setContextFilter($event)" multiple [(ngModel)]="state.filters.context">
            <option *ngFor="let Context of Contexts" value="{{Context}}">{{ContextLabels[Context]}}</option>
        </select>
    </div>
    <div>
        <label for="priority">Search by Priority</label>
        <select id="prioritySelect" (change)="setPriorityFilter($event)" [(ngModel)]="state.filters.priority">
            <option value=""></option>
            <option *ngFor="let Priority of Priorities" value="{{Priority}}">{{PriorityLabels[Priority]}}</option>
        </select>
    </div>
    <div>
        <label for="type">Search by Type</label>
        <select id="typeSelect" (change)="setTypeFilter($event)" [(ngModel)]="state.filters.type">
            <option value=""></option>
            <option *ngFor="let Type of Types" value="{{Type}}">{{TypeLabels[Type]}}</option>
        </select>
    </div>
    <div>
        <label for="deadline">Search by deadline</label>
        <input id="deadlineFilter" [(ngModel)]="model" [owlDateTimeTrigger]="dtDeadlineFilter"
            [owlDateTime]="dtDeadlineFilter" title="deadlineFilter" [selectMode]="'range'"
            (keyup)="stopPropagation($event)" (keypress)="stopPropagation($event)" (keydown)="stopPropagation($event)">
        <owl-date-time [pickerType]="'calendar'" #dtDeadlineFilter></owl-date-time>
    </div>
    <div>
        <label for="group">Search By Group</label>
        <br>
        <xodos-filter-group [state]="state" [filterFunction]="applyFilters" [groupService]="groupService">
        </xodos-filter-group>
    </div>
</div>
