import { IModalDialogOptions, IModalDialog } from '@preeco-privacy/ngx-modal-dialog';
import { Component, ComponentRef } from '@angular/core';
import { Task } from '../Task';

@Component({
  selector: "xodos-create-task-node",
  templateUrl: "./create-task-node.component.html"
})
export class CreateTaskNodeComponent implements IModalDialog {

  options;

  task: Task;

  relationshipName: string;

  constructor() {

  }

  async dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<any>>) {
    this.options = options;
    this.init();
  }

  init() {
    this.task = new Task();
  }


  save() {
    if (!!this.options && !!this.options.data && !!this.task.brief && !!this.relationshipName) {
      this.options.data.resolve({ object: this.task, linkText: this.relationshipName, linkType: "person" }); //TODO introduce interface for resolve parameter
      this.options.closeDialogSubject.next();
    }
  }

  cancel() {
    this.options.closeDialogSubject.next();
  }
}
