import { Component, Input } from '@angular/core';
import { AbstractGroupComponent, SET } from '../group/group.component';
import { Group } from '../group/component/Group';
import { Task } from '../../task/Task';

@Component({
  selector: 'xodos-set-group',
  templateUrl: './set-group.component.html',
  styleUrls: ['../group/group.component.scss']
})
export class SetGroupComponent extends AbstractGroupComponent {
  constructor() {
    super();
    this.groupType = SET;
  }

  @Input() task: Task;

  filterFunction;
  state;

  clicked(group: Group, event?: Event) {
    if (!!this.task) {
      if (this.task.assignee == group) {
        this.task.assignee = null;
      } else {
        this.task.assignee = group;
      }
      event.stopPropagation();
    }
  }

  isSelected() {
    if (!!this.task && !!this.task.assignee && !!this.group) {
      return this.task.assignee.id == this.group.id;
    }
    return false;
  }
}
