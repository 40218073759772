import { Component } from '@angular/core';
import { AbstractGroupComponent, FEED } from '../overview/group/group.component';
import { FeedGroupContainerService, feedGroupEmitter } from './feedGroupContainer.service';
import { Group } from '../overview/group/component/Group';


//TODO xodos
@Component({
  selector: 'app-feed-group',
  templateUrl: '../overview/group/group.component.html',
  styleUrls: ['../overview/group/group.component.scss']
})
export class FeedGroupComponent extends AbstractGroupComponent {

  constructor(private feedGroupContainerService: FeedGroupContainerService) {
    super();
    this.groupType = FEED;
  }


  clicked(group: Group, event: Event) {
    if (this.feedGroupContainerService.feedGroup == group) {
      feedGroupEmitter.emit(null);
    } else {
      feedGroupEmitter.emit(group);
    }
    event.stopPropagation();
  }

  isSelected() {
    return this.isGroupOrAnchestorsSelected(this.group);
  }

  isGroupOrAnchestorsSelected(group: Group): boolean {
    if (!this.feedGroupContainerService.feedGroup) {
      return false;
    }
    if (!group) {
      return false;
    }

    if (group.id === this.feedGroupContainerService.feedGroup.id) {
      return true;
    }

    if (!group.parent) {
      return false;
    }

    let parentGroup = this.groupService.getGroup(group.parent.id);
    return this.isGroupOrAnchestorsSelected(parentGroup);
  }
}
