export let faviconData = {
    icons: {
        "tree": {
            type: "image/png",
            href: "./assets/icon/tree-fav.png",
            isDefault: true
        },
        "login": {
            type: "image/png",
            href: "./assets/icon/login-fav.png"
        },
        "profile": {
            type: "image/png",
            href: "./assets/icon/profile-fav.png"
        },
        "config": {
            type: "image/png",
            href: "./assets/icon/config-fav.png"
        },
        "worklog": {
            type: "image/png",
            href: "./assets/icon/worklog-fav.png"
        }
    },

    cacheBusting: true
};
