import { Component, Input } from '@angular/core';
import { Worklog } from '../Worklog';

@Component({
  selector: '[xodos-worklog-item]',
  templateUrl: './worklog-item.component.html',
  styleUrls: ['./worklog-item.component.scss']
})
export class WorklogItemComponent {

  @Input() worklog: Worklog;

}
