import { Status } from '../../../common/model/Status';
import { HistoryItem } from '../../../common/model/HistoryItem';


export class StatusChange implements HistoryItem {
    id: number;
    user: string;
    created: Date;
    oldStatus: Status;
    newStatus: Status;
}