import { TabModule } from 'angular-tabs-component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { XodosComponent } from './xodos.component';
import { Favicons, BrowserFavicons, BROWSER_FAVICONS_CONFIG } from './common/services/favicons';
import { faviconData } from './data';
import { FeedGroupContainerService } from './feed/feedGroupContainer.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { XoCommonModule, MainPageComponent, ComponentDisplayer, /* routes */ } from "@xo/client-common";
import { modules } from './xodos.service';;
import { DatePipe } from '@angular/common';
import { TaskElementTransformator } from './task/task.service';

let routes = [
  { path: 'component/:componentModule/:componentType', component: ComponentDisplayer },
  { path: 'main/:systemName/:componentModule/:componentType/:title/:parameters', component: MainPageComponent },
  { path: 'main/:systemName/:componentModule/:componentType/:title', component: MainPageComponent },
  { path: 'main', component: MainPageComponent },
  { path: '**', redirectTo: 'main' }
];

/*
const routes = [
  { path: 'main', component: MainPageComponent },
  { path: 'tree/filter', children: [{ path: '**', component: OverviewPage }] },
  { path: 'tree/:taskLabelFilter/filter', children: [{ path: '**', component: OverviewPage }] },
  { path: 'tree/:isTemplate/:taskLabelFilter', component: OverviewPage },
  { path: 'tree/:isTemplate/:taskLabelFilter/filter', children: [{ path: '**', component: OverviewPage }] },
  { path: 'tree', redirectTo: 'tree/filter' },
  { path: 'profile', component: ProfileComponent },
  { path: 'worklog/:taskid/:statusChange', component: WorklogComponent },
  { path: 'worklog/:taskid', component: WorklogComponent },
  { path: '**', redirectTo: 'main' }
];
*/
@NgModule({
  declarations: [
    modules
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    FormsModule,
    XoCommonModule,
    HttpClientModule,
    TabModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  providers: [
    DatePipe,
    {
      provide: Favicons,
      useClass: BrowserFavicons
    },
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: faviconData
    },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'hu' },
    FeedGroupContainerService,
    TaskElementTransformator,
  ],
  bootstrap: [XodosComponent],
  entryComponents: modules,
  exports: modules
})
export class XodosModule { }
