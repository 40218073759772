export const BLACK_AND_WHITE = 'style1';
export const COLORED = 'style2';

export const Styles = [
    BLACK_AND_WHITE,
    COLORED
];

export const StyleLabels = {};
StyleLabels[BLACK_AND_WHITE] = ' Fekete & Feher';
StyleLabels[COLORED] = ' Szines';
