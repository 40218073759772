import { ConfigService, SystemUrlService } from "@xo/client-common";

export class TaskUrlService extends SystemUrlService {
    constructor(system: string, configService: ConfigService) {
        super(system, "Xodos", "Task", configService);
    }

    getTemplateUrl(taskCode: string, templateCode: string) {
        return this.baseURL + "/" + taskCode + "/applyTemplate/" + templateCode;
    }

    getMassCreateUrl(taskCode: string) {
        return this.baseURL + "/" + taskCode + "/masscreate";
    }

    getExportUrl(taskCode: string, type: string) {
        return this.baseURL + "/" + taskCode + "/export." + type;
    }

    createFeedUrl(params): string {
        return `${this.baseURL}?feedStatus=${params.feedStatus}&feedTime=${params.feedTime}&feedGroup=${params.feedGroup}`;
    }
}
