export const LOW = 'LOW';
export const NORMAL = 'NORMAL';
export const HIGH = 'HIGH';

export let Priorities = [
    'LOW',
    'NORMAL',
    'HIGH'
];

export let PriorityLabels = {}
PriorityLabels[LOW] = "Low"
PriorityLabels[NORMAL] = "Normal"
PriorityLabels[HIGH] = "High"