export const CONTEXT_HOME = 'HOME';
export const CONTEXT_WORK = 'WORK';
export const CONTEXT_PHONE = 'PHONE';
export const CONTEXT_PROJECT = 'PROJECT';

export let Contexts = [
    CONTEXT_HOME,
    CONTEXT_WORK,
    CONTEXT_PHONE,
    CONTEXT_PROJECT
];

export let ContextLabels = {};
ContextLabels[CONTEXT_HOME] = 'Home';
ContextLabels[CONTEXT_WORK] = 'Work';
ContextLabels[CONTEXT_PHONE] = 'Phone';
ContextLabels[CONTEXT_PROJECT] = 'Project';
